import { DataStore, SortDirection } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import {
  setListing,
  setSearchText,
  setSelected,
} from "../store/ducks/timeline";
import { HeadCell } from "../models/dataTable";
import { Timeline } from "../models";
import { BookingTimelineActions, TimelineActions } from "../constants/enums";
import { getFormattedDate, mergeListOfStringsByDash } from "../helpers/utils";
import { CreateTimelineInput, TimelineQueryVariables } from "../models/api";
import useApp from "./useApp";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { session, showConfirm, showError } = useApp();

  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );
  const selected = useSelector(
    (state: any) => state[`${listingName}`]["selected"]
  );

  async function fetch(props: TimelineQueryVariables) {
    const { startIndex, limit, bookingId, customerId } = props;

    try {
      const listing = await DataStore.query(
        Timeline as any,
        (model: any) => {
          model.deleted("eq", "0");

          if (bookingId) model.bookingId("eq", bookingId);
          if (customerId) model.customerId("eq", customerId);

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      // dispatch(setListing(listing));

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message);
    }
  }

  async function fetchAll() {
    try {
      const listing = await DataStore.query(
        Timeline as any,
        (user: any) => user.deleted("eq", "0"),
        {}
      );
      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function get(id: string) {
    try {
      const single: Timeline =
        listing.length === 0
          ? await DataStore.query(Timeline as any, id)
          : listing.find((model: any) => model.id === id);

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(data: any, resource: any) {
    if (data.bookingId) {
      if (
        data.accompaniedCount &&
        data.accompaniedCount !== resource.accompaniedCount
      ) {
        const actionName = BookingTimelineActions.GUEST_COUNT;
        const oldData = resource.accompaniedCount
          ? resource.accompaniedCount.toString()
          : "";
        const newData = data.accompaniedCount.toString();

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: data.bookingId,
          customerId: "",
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: session.sub,
          createdByName: session.name,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...listing]));
      }

      if (data.date && data.date !== resource.date) {
        const actionName = BookingTimelineActions.DATE;
        const oldData = resource.date ? resource.date : "";
        const newData = data.date;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: data.bookingId,
          customerId: "",
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: session.sub,
          createdByName: session.name,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...listing]));
      }

      if (data.timeSlotID && data.timeSlotID !== resource.timeSlotID) {
        const actionName = BookingTimelineActions.TIME_SLOT;
        const oldData = "OldData";
        const newData = "NewData";

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: data.bookingId,
          customerId: "",
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: session.sub,
          createdByName: session.name,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...listing]));
      }

      if (data.statusID && data.statusID !== resource.statusID) {
        const actionName = BookingTimelineActions.STATUS;
        const oldData = "oldData";
        const newData = "newData";

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: data.bookingId,
          customerId: "",
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: session.sub,
          createdByName: session.name,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...listing]));
      }

      if (data.tableName && data.tableName !== resource.tableName) {
        const actionName = BookingTimelineActions.TABLE;
        const oldData = resource.tableName;
        const newData = data.tableName;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: data.bookingId,
          customerId: "",
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: session.sub,
          createdByName: session.name,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...listing]));
      }
    }

    if (data.customerId) {
      if (
        data.flags &&
        data.flags.length > 0 &&
        data.flags !== resource.flags
      ) {
        let actionName = TimelineActions.ADD_FLAG;

        if (data.flags.length < resource.flags.length)
          actionName = TimelineActions.REMOVE_FLAG;

        const oldData = mergeListOfStringsByDash(resource.flagsName);
        const newData = mergeListOfStringsByDash(data.flagsName);

        if (newData) {
          const createInput: CreateTimelineInput = {
            actionName: actionName,
            oldStatus: oldData,
            newStatus: newData,
            bookingId: "",
            customerId: data.customerId,
            deleted: "0",
            createdAt: new Date().toISOString(),
            createdByID: session.sub,
            createdByName: session.name,
          };

          const timeline = await DataStore.save(
            new Timeline(createInput as any)
          );

          dispatch(setListing([timeline, ...listing]));
        }
      }

      if (data.interests && data.interests !== resource.interests) {
        let actionName = TimelineActions.ADD_INTEREST;

        if (data.interests.length < resource.interests.length)
          actionName = TimelineActions.REMOVE_INTEREST;

        const oldData = mergeListOfStringsByDash(resource.interestsName);
        const newData = mergeListOfStringsByDash(data.interestsName);

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: session.sub,
          createdByName: session.name,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...listing]));
      }

      if (data.phone_number && data.phone_number !== resource.phone_number) {
        const actionName = TimelineActions.CHANGE_PHONE_NUMBER;
        const oldData = resource.phone_number ? resource.phone_number : "";
        const newData = data.phone_number;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: session.sub,
          createdByName: session.name,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...listing]));
      }

      if (
        data.email_verified &&
        data.email_verified !== resource.email_verified
      ) {
        const actionName = TimelineActions.CHANGE_VERIFICATION_STATUS;
        const oldData = resource.email_verified ? resource.email_verified : "";
        const newData = data.email_verified;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: session.sub,
          createdByName: session.name,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...listing]));
      }

      if (data.email && data.email !== resource.email) {
        const actionName = TimelineActions.CHANGE_EMAIL;
        const oldData = resource.email ? resource.email : "";
        const newData = data.email;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: session.sub,
          createdByName: session.name,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...listing]));
      }

      if (data.group && data.group !== resource.group) {
        const actionName = TimelineActions.CHANGE_GROUP;
        const oldData = resource.group ? resource.group : "";
        const newData = data.group;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: session.sub,
          createdByName: session.name,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...listing]));
      }

      if (data.name && data.name !== resource.name) {
        const actionName = TimelineActions.CHANGE_NAME;
        const oldData = resource.name ? resource.name : "";
        const newData = data.name;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: session.sub,
          createdByName: session.name,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...listing]));
      }

      if (data.instagram && data.instagram !== resource.instagram) {
        const actionName = TimelineActions.CHANGE_INSTAGRAM;
        const oldData = resource.instagram ? resource.instagram : "";
        const newData = data.instagram;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: session.sub,
          createdByName: session.name,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...listing]));
      }

      if (data.facebook && data.facebook !== resource.facebook) {
        const actionName = TimelineActions.CHANGE_FACEBOOK;
        const oldData = resource.facebook ? resource.facebook : "";
        const newData = data.facebook;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: session.sub,
          createdByName: session.name,
        };

        const timeline = await DataStore.save(new Timeline(createInput as any));

        dispatch(setListing([timeline, ...listing]));
      }
    }
  }

  async function update(id: string, data: any) {
    try {
      const original = await get(id);
      if (!original) {
        showError("Can not find this timeline.");
        return;
      }
      const update = await DataStore.save(
        Timeline.copyOf(original!, (updated) => {
          updated.actionName = data.actionName
            ? data.actionName
            : original!.actionName;
          updated.oldStatus = data.oldStatus
            ? data.oldStatus
            : original!.oldStatus;
          updated.newStatus = data.newStatus
            ? data.newStatus
            : original!.newStatus;
          updated.bookingId = data.bookingId
            ? data.bookingId
            : original!.bookingId;
          updated.customerId = data.customerId
            ? data.customerId
            : original!.customerId;
          updated.createdAt = data.createdAt
            ? data.createdAt
            : original!.createdAt;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
      return update;
    } catch (err) {
      showError(err);
    }
  }

  async function trash(id: string) {
    try {
      const original = await get(id);

      await DataStore.save(
        Timeline.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(ids: any) {
    for (let id of ids) {
      try {
        await trash(id);
      } catch (err: Error | any) {
        showError(err);
      }
    }

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(id: any) {
    try {
      await DataStore.delete(id);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      console.log(err);
      showError(err);
    }
  }

  /**
   * Fix user creation date
   *
   * @returns void
   */
  async function fixData() {
    try {
      const timelines = await fetchAll();

      if (timelines) {
        for (let timeline of timelines) {
          const formattedDate = getFormattedDate(timeline.createdAt);
          const updateTimeline = {
            createdAt: formattedDate,
          };
          await update(timeline.id, updateTimeline);
        }
      }
      showConfirm(`Users has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function exportAll(params: TimelineQueryVariables) {
    try {
      let exportedData = [];

      const data = await fetch(params);

      for (let user of data!) {
        let row: any = { ...user };
        exportedData.push(row);
      }

      return exportedData;
    } catch (err) {
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "actionName",
      numeric: false,
      disablePadding: false,
      label: "Action Name",
    },
    {
      id: "oldStatus",
      numeric: false,
      disablePadding: false,
      label: "Old Status",
    },
    {
      id: "newStatus",
      numeric: false,
      disablePadding: false,
      label: "New Status",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["actionName", "oldStatus", "newStatus"];

  const options: any[] = [];

  for (let option of listing) {
    options.push({ label: option.name, value: option.id });
  }

  const api: any = {};

  api[`${listingName}Listing`] = listing;
  api[`${listingName}Options`] = options;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Model`] = Timeline as any;
  api[`${listingName}Selected`] = selected;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}FixData`] = fixData;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}ChangeListing`] = (listing: Timeline[]) =>
    dispatch(setListing(listing));
  api[`${listingName}Search`] = (searchText: string) =>
    dispatch(setSearchText(searchText));
  api[`${listingName}ChangeListing`] = (listing: Timeline[]) =>
    dispatch(setListing(listing));
  api[`${listingName}Select`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useResource;
