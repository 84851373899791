import { Auth } from "aws-amplify";
import { Pages } from "../constants/enums";
import { getDomainName } from "../helpers/utils";
import useAccount from "./useAccount";
import useApp from "./useApp";
import useFeature from "./useFeature";
import useConcept from "./useConcept";
import { DataStore } from "aws-amplify";
import { ListingByAccountVariables } from "../models/app";

const useAuth = () => {
  const { showError, showConfirm, setSession, clearSession, navigateTo } =
    useApp();
  const { accountsFetchByDomainOnline } = useAccount("accounts", "account");
  const { featuresFetchByAccount } = useFeature("features", "feature");
  const { conceptsFetch, conceptsChangeListing } = useConcept(
    "concepts",
    "concept"
  );

  async function register(data: any) {
    try {
      await Auth.signUp({
        username: `${getDomainName()}_${data.email}`,
        password: data.password,
        attributes: {
          name: data.name,
          email: `${getDomainName()}_${data.email}`,
        },
      });

      navigateTo("/");
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
      console.log("error signing up:", err);
    }
  }

  async function login(data: any) {
    try {
      const user = await Auth.signIn({
        username: `${getDomainName()}_${data.email}`,
        password: data.password,
      });

      const session = user.signInUserSession.idToken.payload;
      setSession(session);

      DataStore.start();
      const accounts = await accountsFetchByDomainOnline(session);
      const listing = await featuresFetchByAccount();
      const result = await Promise.all([accounts, listing]);

      const listingByAccountParams: ListingByAccountVariables = {
        accountID: result[0].id,
        searchText: "",
        startIndex: 0,
        limit: 1000,
      };

      const concepts = await conceptsFetch(listingByAccountParams);
      conceptsChangeListing(concepts);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
      console.log("error signing in", err);
    }
  }

  async function logout() {
    try {
      await Auth.signOut();

      clearSession();
      navigateTo("/");
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
      console.log("error signing out: ", err);
    }
  }

  async function forgetPassword(data: any) {
    try {
      // Send confirmation code to user's email
      await Auth.forgotPassword(`${getDomainName()}_${data.email}`);
      showConfirm("Verification code has been sent to your email.");
      navigateTo(`/${Pages.RESET_PASSWORD}`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
      console.log("error sending code to email: ", err);
    }
  }

  async function forgetPasswordSubmit(data: any) {
    try {
      const resetPassword = await Auth.forgotPasswordSubmit(
        `${getDomainName()}_${data.email}`,
        data.code,
        data.newPassword
      );
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
      console.log(err);
    }
  }

  return { login, register, logout, forgetPassword, forgetPasswordSubmit };
};

export default useAuth;
