import { DataStore, SortDirection } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import {
  setListing,
  setSearchText,
  setSelected,
} from "../store/ducks/orderedItem";
import { OrderedItems } from "../models";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { User } from "../models";
import useConcept from "./useConcept";
import useGroup from "./useGroup";
import { GLOBAL_GUEST_ID } from "../constants/enums";
import { ListingVariables } from "../models/app";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError } = useApp();
  const { conceptsSelected, conceptsSelectedFilters } = useConcept(
    "concepts",
    "concept"
  );
  const { groupsGetName } = useGroup("groups", "group");

  const listing = useSelector((state: any) => {
    return state.orderedItems.listing;
  });

  const searchText = useSelector((state: any) => {
    return state.orderedItems.searchText;
  });

  async function listTopCustomers(params: any) {
    const { conceptID, startIndex, limit } = params;

    const listing = await DataStore.query(
      User as any,
      (user: any) => {
        user.deleted("eq", "0");

        if (
          conceptID &&
          !(conceptsSelectedFilters && conceptsSelectedFilters.length > 0)
        ) {
          user.or((model: any) => {
            model.conceptID("contains", conceptID);
          });
        }

        if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
          user.or((model: any) => {
            for (let filter of conceptsSelectedFilters) {
              model.conceptID("contains", filter.id);
            }
          });
        }

        return user;
      },
      {
        page: startIndex / limit,
        limit: limit,
        sort: (s) => s.orders_count(SortDirection.DESCENDING),
      }
    );
    return listing;
  }

  async function listUserOrderedItemsCount(params: any) {
    const listing = await DataStore.query(
      OrderedItems as any,
      (order: any) => {
        order.deleted("eq", "0").userID("eq", params.userId);
        return order;
      },
      {
        page: 0,
        limit: 1000,
        sort: (s) => s.count(SortDirection.DESCENDING),
      }
    );
    return listing;
  }

  async function listTopOrderedItems(params: any) {
    const listing = await DataStore.query(
      OrderedItems as any,
      (order: any) => {
        order
          .deleted("eq", "0")
          .userID("eq", params.userId)
          .isGlobal("eq", "1");

        if (
          params.conceptID &&
          order.conceptID &&
          !(conceptsSelectedFilters && conceptsSelectedFilters.length > 0)
        ) {
          order.or((model: any) => {
            model.conceptID("contains", params.conceptID);
          });
        }

        if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
          order.or((model: any) => {
            for (let filter of conceptsSelectedFilters) {
              model.conceptID("contains", filter.id);
            }
          });
        }
        return order;
      },
      {
        page: params.startIndex / params.limit,
        limit: params.limit,
        sort: (s) => s.count(SortDirection.DESCENDING),
      }
    );
    return listing;
  }

  async function fetch(props: ListingVariables) {
    const { searchText, startIndex, limit } = props;

    try {
      const listing = await DataStore.query(
        OrderedItems as any,
        (order: any) => {
          order.deleted("eq", "0");
          order.userID("eq", GLOBAL_GUEST_ID);
          if (
            order.conceptID &&
            conceptsSelected &&
            !(conceptsSelectedFilters && conceptsSelectedFilters.length > 0)
          ) {
            order.or((model: any) => {
              model.conceptID("contains", conceptsSelected);
            });
          }

          if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
            order.or((user: any) => {
              for (let filter of conceptsSelectedFilters) {
                if (user.conceptID) {
                  user.conceptID("contains", filter.id);
                }
              }
            });
          }

          if (searchText.length > 0) {
            order.or((user: any) => {
              user.itemName("contains", searchText);
            });
          }

          return order;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.count(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function fetchOrdered(props: ListingVariables) {
    try {
      const { searchText, startIndex, limit } = props;

      const listing = await DataStore.query(
        OrderedItems as any,
        (order: any) => {
          order.deleted("eq", "0");
          order.userID("eq", GLOBAL_GUEST_ID);
          if (
            order.conceptID &&
            conceptsSelected &&
            !(conceptsSelectedFilters && conceptsSelectedFilters.length > 0)
          ) {
            order.or((model: any) => {
              model.conceptID("contains", conceptsSelected);
            });
          }

          if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
            order.or((user: any) => {
              for (let filter of conceptsSelectedFilters) {
                if (user.conceptID) {
                  user.conceptID("contains", filter.id);
                }
              }
            });
          }

          if (searchText.length > 0) {
            order.or((user: any) => {
              user.itemName("contains", searchText);
            });
          }

          return order;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.count(SortDirection.DESCENDING),
        }
      );
      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function exportAll(params: any) {
    try {
      let exportedData = [];

      const data = await fetch(params);

      for (let user of data!) {
        let row: any = { ...user };
        if (user.group) {
          row.groupName = groupsGetName(user.group);
        }
        exportedData.push(row);
      }

      return exportedData;
    } catch (err) {
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "itemName",
      numeric: false,
      disablePadding: false,
      label: "Item Name",
    },
    {
      id: "itemCount",
      numeric: false,
      disablePadding: false,
      label: "Sold Count",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Create Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["itemName", "count"];

  const api: any = {};
  api[`${listingName}Listing`] = listing;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}ListTopCustomers`] = listTopCustomers;
  api[`${listingName}ListTopOrderedItems`] = listTopOrderedItems;
  api[`${listingName}ListUserOrderedItemsCount`] = listUserOrderedItemsCount;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchOrdered`] = fetchOrdered;
  api[`${listingName}Export`] = exportAll;

  api[`${listingName}ChangeListing`] = (listing: OrderedItems[]) =>
    dispatch(setListing(listing));
  api[`${listingName}Search`] = (searchText: string) =>
    dispatch(setSearchText(searchText));
  api[`${listingName}Select`] = (orderedItemID: string) =>
    dispatch(setSelected(orderedItemID));

  return api;
};
export default useResource;
