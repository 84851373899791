import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API, DataStore, SortDirection } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsSearching,
  setListing,
  setSearchText,
  setSelected,
} from "../store/ducks/user";
import { User } from "../models";
import { HeadCell } from "../models/dataTable";
import { CreateUserInput } from "../models/GQL_API";
import useApp from "./useApp";
import useAccount from "./useAccount";
import {
  extractSelectedCheckboxes,
  validatePhone,
} from "../helpers/utils";
import useTimeline from "./useTimeline";
import useGroup from "./useGroup";
import { getUser } from "../graphql/queries";
import { updateUser } from "../graphql/mutations";
import { ListingVariables, TransactionListingVariables } from "../models/app";
import useTier from "./useTier";
import useConcept from "./useConcept";
const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { session, showConfirm, showError, setSelectedUser } =
    useApp();
  const { groupsSelectedFilters, groupsOptions, groupsGetName } = useGroup(
    "groups",
    "group"
  );
  const { tiersGetName, tiersSelectedFilters } = useTier("tiers", "tier");
  const { accountsSelected } = useAccount("accounts", "account");
  const { timelinesCreate } = useTimeline("timelines", "timelines");
  const { conceptsSelectedFilters, conceptsSelected } = useConcept(
    "concepts",
    "concept"
  );
  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );
  const selected = useSelector(
    (state: any) => state[`${listingName}`]["selected"]
  );
  const isSearching = useSelector(
    (state: any) => state[`${listingName}`]["isSearching"]
  );

  async function fetch(props: ListingVariables) {
    try {
      const { searchText, startIndex, limit } = props;

      const listing = await DataStore.query(
        User as any,
        (user: any) => {
          user.deleted("eq", "0");

          if (
            user.conceptID &&
            conceptsSelected &&
            !(conceptsSelectedFilters && conceptsSelectedFilters.length > 0)
          ) {
            user.or((model: any) => {
              model.conceptID("contains", conceptsSelected);
            });
          }

          if (groupsSelectedFilters.length > 0) {
            user.or((user: any) => {
              for (let filter of groupsSelectedFilters) {
                user.group("contains", filter.id);
              }
            });
          }

          if (tiersSelectedFilters && tiersSelectedFilters.length > 0) {
            user.or((user: any) => {
              for (let filter of tiersSelectedFilters) {
                user.tier("contains", filter.id);
              }
            });
          }

          if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
            user.or((user: any) => {
              for (let filter of conceptsSelectedFilters) {
                if (user.conceptID) {
                  user.conceptID("contains", filter.id);
                }
              }
            });
          }

          // var endDate = new Date(dateRange[0].endDate.getTime() + 86400000);
          // const fromDate = getDateFormatted(dateRange[0].startDate);
          // const toDate = getDateFormatted(endDate);

          // if (fromDate && toDate) {
          //   if (fromDate === toDate) {
          //     user.and((model: any) => {
          //       model.createdAt("contains", fromDate);
          //     });
          //   } else {
          //     user.and((model: any) => {
          //       model.createdAt("ge", fromDate).createdAt("le", toDate);
          //     });
          //   }
          // }

          if (searchText.length > 0) {
            user.or((user: any) => {
              user.given_name("contains", searchText.toLowerCase());
              user.family_name("contains", searchText.toLowerCase());
              user.nickname("contains", searchText.toLowerCase());
              user.username("contains", searchText.toLowerCase());
              user.name("contains", searchText.toLowerCase());
              user.phone_number("contains", searchText);
            });
          }

          return user;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );
      // dispatch(setListing(listing));
      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function fetchOrdered(props: ListingVariables) {
    try {
      const { searchText, startIndex, limit } = props;

      const listing = await DataStore.query(
        User as any,
        (user: any) => {
          user.deleted("eq", "0");
          if (
            user.conceptID &&
            conceptsSelected &&
            !(conceptsSelectedFilters && conceptsSelectedFilters.length > 0)
          ) {
            user.or((model: any) => {
              model.conceptID("contains", conceptsSelected);
            });
          }
          if (groupsSelectedFilters.length > 0) {
            user.or((user: any) => {
              for (let filter of groupsSelectedFilters) {
                user.group("contains", filter.id);
              }
            });
          }

          if (tiersSelectedFilters && tiersSelectedFilters.length > 0) {
            user.or((user: any) => {
              for (let filter of tiersSelectedFilters) {
                user.tier("contains", filter.id);
              }
            });
          }
          if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
            user.or((user: any) => {
              for (let filter of conceptsSelectedFilters) {
                if (user.conceptID) {
                  user.conceptID("contains", filter.id);
                }
              }
            });
          }

          if (searchText.length > 0) {
            user.or((user: any) => {
              user.given_name("contains", searchText.toLowerCase());
              user.family_name("contains", searchText.toLowerCase());
              user.nickname("contains", searchText.toLowerCase());
              user.username("contains", searchText.toLowerCase());
              user.name("contains", searchText.toLowerCase());
              user.phone_number("contains", searchText);
            });
          }

          return user;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.orders_count(SortDirection.DESCENDING),
        }
      );
      // dispatch(setListing(listing));
      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function fetchAll() {
    try {
      const listing = await DataStore.query(
        User as any,
        (user: any) => user.deleted("eq", "0"),
        {}
      );
      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function fetchBy(data: string) {
    try {
      const listing = await DataStore.query(
        User as any,
        (user: any) => {
          user.deleted("eq", "0");

          if (data.length > 0) {
            user.or((user: any) => {
              user.email("contains", data);
              user.phone_number("contains", data);
            });
          }

          return user;
        },
        {}
      );
      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function lazyLoad(startIndex: number, limit: number) {
    try {
      const listing = await DataStore.query(
        User as any,
        (user: any) =>
          user
            .deleted("eq", "0")
            .or((user: any) => {
              for (let filter of groupsSelectedFilters) {
                user.group("contains", filter.id);
              }
              return user;
            })
            .or((user: any) => {
              for (let filter of tiersSelectedFilters) {
                user.tier("contains", filter.id);
              }
              return user;
            })

            .or((user: any) => {
              user.given_name("contains", searchText.toLowerCase());
              user.family_name("contains", searchText.toLowerCase());
              user.nickname("contains", searchText.toLowerCase());
              user.username("contains", searchText.toLowerCase());
              user.name("contains", searchText.toLowerCase());
              user.phone_number("contains", searchText);
            }),
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function exportAll(params: TransactionListingVariables) {
    try {
      let exportedData = [];

      const data = await fetch(params);

      for (let user of data!) {
        let row: any = { ...user };
        if (user.group) {
          row.groupName = groupsGetName(user.group);
        }
        exportedData.push(row);
      }

      return exportedData;
    } catch (err) {
      showError(err);
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (id: string) => {
    if (listing.length > 0) {
      const model = listing.find((model: User) => model.id === id);
      return model ? model.name : "";
    } else {
      return "";
    }
  };

  async function get(resourceId: string) {
    try {
      let single: User | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === resourceId);
      }

      if (single === undefined) {
        single = await DataStore.query(User as any, resourceId);
      }

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function getOnline(id: string) {
    try {
      const user: any = await API.graphql({
        query: getUser,
        variables: { id: id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return user.data.getUser;
    } catch (err) {
      showError(err);
    }
  }

  /**
   * Construct name from given_name, middle_name, and family_name
   *
   * @param given_name given_name: string
   * @param middle_name middle_name: string
   * @param family_name family_name: string
   *
   * @returns string
   */
  const constructName = (
    given_name: string,
    middle_name: string,
    family_name: string
  ) => {
    if (
      given_name.length > 0 &&
      middle_name.length > 0 &&
      family_name.length > 0
    ) {
      return (
        given_name.trim() + " " + middle_name.trim() + " " + family_name.trim()
      );
    }

    if (given_name.length > 0 && family_name.length > 0) {
      return given_name.trim() + " " + family_name.trim();
    }

    if (given_name.length > 0 && middle_name.length > 0) {
      return given_name.trim() + " " + middle_name.trim();
    }

    return given_name;
  };

  async function create(data: any, stateData: any) {
    try {
      const flags: string[] = extractSelectedCheckboxes("flags_", data);
      const flagsName: string[] = [];
      const interests: string[] = extractSelectedCheckboxes("interests_", data);
      const interestsName: string[] = [];

      if (data.phone_number && !validatePhone(data.phone_number)) {
        throw new Error("Phone number is not valid");
      }

      if (data.email) {
        let userExists = await fetchBy(data.email);
        if (userExists !== undefined && userExists.length > 0)
          throw new Error("Email already exists");
      }

      if (data.phone_number) {
        let userExists = await fetchBy(data.phone_number);
        if (userExists !== undefined && userExists.length > 0)
          throw new Error("Phone Number already exists");
      }

      if (data.birthdate) {
        if (
          data.birthdate <= "1940-01-01" ||
          data.birthdate >= `${new Date().getFullYear() - 10}-01-01`
        ) {
          throw new Error("Invalid Date of Birth");
        }
      }
      const createInput: CreateUserInput = {
        accountID: accountsSelected.id,
        conceptID: conceptsSelected,
        phone_number: data.phone_number,
        given_name: data.given_name.toLowerCase(),
        middle_name: data.middle_name ? data.middle_name.toLowerCase() : "",
        family_name: data.family_name.toLowerCase(),
        nickname: data.nickname ? data.nickname.toLowerCase() : "",
        name: constructName(
          data.given_name.toLowerCase(),
          data.middle_name ? data.middle_name.toLowerCase() : "",
          data.family_name.toLowerCase()
        ),
        address: data.address ? data.address : "",
        birthdate: data.birthdate ? data.birthdate : "",
        picture: data.picture ? data.picture : "",
        website: data.website ? data.website : "",
        facebook: data.facebook ? data.facebook : "",
        instagram: data.instagram ? data.instagram : "",
        username: data.username ? data.username : "",
        group: data.group
          ? data.group
          : groupsOptions.length > 0
          ? groupsOptions.find(
              (item: any) => item.label === "normal" || item.label === "Normal"
            ).value
          : "",
        email: data.email ? data.email : "",
        flags,
        flagsName,
        interests,
        interestsName,
        stats: [],
        total_points: data.total_points ? Number(data.total_points) : 0,
        points_history: data.points_history ? data.points_history : [],
        tier: data.tier ? data.tier : "",
        tier_name: data.tier ? tiersGetName(data.tier) : "",
        total_spending: 0,
        orders_count: 0,
        deleted: "0",
        createdAt: new Date().toISOString(),
        createdByID: session.sub,
        createdByName: session.name,
      };

      const user = await DataStore.save(new User(createInput as any));

      dispatch(setListing([user, ...listing]));

      showConfirm(`New ${singleName} has been created successfully`);
      return user;
    } catch (err: Error | any) {
      showError(err.message || err);
      return;
    }
  }

  async function update(resourceId: string, data: any) {
    const flagsName: string[] = [];
    const interestsName: string[] = [];

    if (data.phone_number && !validatePhone(data.phone_number)) {
      throw new Error("Phone number is not valid");
    }

    if (data.phone_number) {
      let userExists = await fetchBy(data.phone_number);
      if (userExists !== undefined && userExists.length > 0)
        throw new Error("Phone Number already exists");
    }

    if (data.email) {
      let userExists = await fetchBy(data.email);
      if (userExists !== undefined && userExists.length > 0)
        throw new Error("Email already exists");
    }

    try {
      const original = await get(resourceId);
      if (!original) {
        showError("Can not find this user.");
        return;
      }
      let name = original!.name;

      if (data.given_name && data.given_name && data.family_name) {
        name = constructName(
          data.given_name.toLowerCase(),
          "",
          data.family_name.toLowerCase()
        );
      }

      const updated = await DataStore.save(
        User.copyOf(original!, (updated) => {
          updated.conceptID = data.conceptID
            ? data.conceptID
            : original!.conceptID;
          updated.phone_number = data.phone_number
            ? data.phone_number
            : original!.phone_number;
          updated.given_name = data.given_name
            ? data.given_name.toLowerCase()
            : original!.given_name;
          updated.middle_name = data.middle_name
            ? data.middle_name.toLowerCase()
            : original!.middle_name;
          updated.nickname = data.nickname
            ? data.nickname.toLowerCase()
            : original!.nickname;
          updated.family_name = data.family_name
            ? data.family_name.toLowerCase()
            : original!.family_name;
          updated.name = name;
          updated.address = data.address ? data.address : original!.address;
          updated.birthdate = data.birthdate
            ? data.birthdate
            : original!.birthdate;
          updated.picture = data.picture ? data.picture : original!.picture;
          updated.website = data.website ? data.website : original!.website;
          updated.facebook = data.facebook ? data.facebook : original!.facebook;
          updated.instagram = data.instagram
            ? data.instagram
            : original!.instagram;
          updated.username = data.username ? data.username : original!.username;
          updated.group = data.group ? data.group : original!.group;
          updated.email = data.email ? data.email : original!.email;
          updated.flags = data.flags ? data.flags : original!.flags;
          updated.flagsName = data.flags ? flagsName : original!.flagsName;
          updated.interests = data.interests
            ? data.interests
            : original!.interests;
          updated.interestsName = data.interests
            ? interestsName
            : original!.interestsName;
          updated.stats = data.stats ? data.stats : original!.stats;
          updated.total_points = data.total_points
            ? data.total_points
            : original!.total_points;
          updated.points_history = data.points_history
            ? data.points_history
            : original!.points_history;
          updated.tier = data.tier ? data.tier : original!.tier;
          updated.tier_name = data.tier
            ? tiersGetName(data.tier)
            : original!.tier_name;
          updated.points_balance = data.points_balance
            ? data.points_balance
            : original!.points_balance;
          updated.total_spending = data.total_spending
            ? data.total_spending
            : original!.total_spending;
          updated.orders_count = data.orders_count
            ? data.orders_count
            : original!.orders_count;

          updated.createdAt = data.createdAt
            ? data.createdAt
            : original!.createdAt;
        })
      );

      await timelinesCreate(
        { ...data, customerId: resourceId, name, flagsName, interestsName },
        original!
      );

      setSelectedUser(updated);

      showConfirm(`${singleName} has been updated successfully`);
      return updated;
    } catch (err: Error | any) {
      showError(err.message || err);
    }
  }

  /**
   * Fix user creation date
   * Fix total points according to total spending
   * Return to normal tier
   *
   * @returns void
   */
  async function fixUserData() {
    try {
      const users = await fetchAll();

      if (users) {
        for (let user of users) {
          let points_balance = user.total_spending / 10;
          const updateUser = {
            total_points: Math.floor(user.total_spending), // (Rate = 1)
            points_balance: Number(points_balance.toFixed(2)), // (Rate = 10)
            tier: "8a69da49-63d1-48ed-b142-c44266eca42e",
            tier_name: "normal tier",
            createdAt: new Date(user.createdAt).toISOString(),
          };

          const updated = await update(user.id, updateUser);
          // console.log({ updated });
        }
      }
      showConfirm(`Users has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function updateEmailOnline(resourceId: string, data: any) {
    try {
      if (data.email) {
        const updated = await API.graphql({
          query: updateUser,
          variables: {
            input: {
              id: resourceId,
              email: data.email,
            },
          },
          authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
        });

        setSelectedUser(updated);
      }
    } catch (err: Error | any) {
      showError(err.message || err);
    }
  }

  async function trash(resourceId: string) {
    try {
      const original = await get(resourceId);

      await DataStore.save(
        User.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(resourceIds: any) {
    for (let resourceId of resourceIds) {
      try {
        await trash(resourceId);
      } catch (err: Error | any) {
        showError(err);
      }
    }

    dispatch(
      setListing(
        listing.filter((resource: any) => !resourceIds.has(resource.id))
      )
    );

    showConfirm(
      `${resourceIds.size} ${listingName} items has been moved to trash`
    );
  }

  async function remove(resourceId: any) {
    try {
      await DataStore.delete(resourceId);

      dispatch(
        setListing(
          listing.filter((resource: any) => resource.id !== resourceId)
        )
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      console.log(err);
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "phone_number",
      numeric: false,
      disablePadding: false,
      label: "Mobile",
    },
    {
      id: "tier_name",
      numeric: false,
      disablePadding: false,
      label: "Tier",
    },
    {
      id: "total_points",
      numeric: false,
      disablePadding: false,
      label: "Points",
    },
    {
      id: "total_spending",
      numeric: false,
      disablePadding: false,
      label: "Total Purchase",
    },
    {
      id: "points_balance",
      numeric: false,
      disablePadding: false,
      label: "Available Balance",
    },
    {
      id: "group",
      numeric: false,
      disablePadding: false,
      label: "Group",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = [
    "name",
    "phone_number",
    "tier_name",
    "total_points",
  ];

  const options: any[] = [];

  const api: any = {};

  api[`${listingName}Listing`] = listing;
  api[`${listingName}Options`] = options;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Model`] = User as any;
  api[`${listingName}Selected`] = selected;
  api[`${listingName}IsSearching`] = isSearching;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchOrdered`] = fetchOrdered;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}LazyLoad`] = lazyLoad;
  api[`${listingName}Get`] = get;
  api[`${listingName}GetOnline`] = getOnline;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}AddConceptID`] = fixUserData;
  api[`${listingName}UpdateEmailOnline`] = updateEmailOnline;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}ChangeListing`] = (listing: any[]) =>
    dispatch(setListing(listing));
  api[`${listingName}Search`] = (searchText: string) =>
    dispatch(setSearchText(searchText));
  api[`${listingName}ChangeIsSearching`] = (payload: boolean) =>
    dispatch(setIsSearching(payload));
  api[`${listingName}Select`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useResource;
