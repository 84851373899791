// actions
const SET = "tiers/SET";
const SET_STATS = "tiers/SET_STATS";
const SET_SEARCH_TEXT = "tiers/SET_SEARCH_TEXT";
const SET_SELECTED = "tiers/SET_SELECTED";
const SET_SELECTED_FILTERS="tiers/SET_SELECTED_FILTERS"
const DEFAULT_STATE = {
  listing: [],
  listingStats: [],
  searchText: "",
  selected: "",
  selectedFilters: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SELECTED_FILTERS:
      return Object.assign({}, state, { selectedFilters: action.listing });
    case SET_STATS:
      return Object.assign({}, state, { listingStats: action.listingStats });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_SELECTED:
      return Object.assign({}, state, { conceptsSelected: action.concept });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setListingStats = (listingStats: any[]) => ({
  listingStats,
  type: SET_STATS,
});

export const setSearchText = (searchText: string) => ({
  searchText,
  type: SET_SEARCH_TEXT,
});
export const setSelectedFilters = (listing: any[]) => ({
  listing,
  type: SET_SELECTED_FILTERS,
});

export const setSelected = (single: any) => ({ single, type: SET_SELECTED });
