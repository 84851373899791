import useAccount from "./useAccount";
import useConcept from "./useConcept";
import useFeature from "./useFeature";
import useGroup from "./useGroup";
import useGroupSetting from "./useGroupSetting";
import useSocialLink from "./useSocialLink";
import useTimeline from "./useTimeline";
import useAttachment from "./useAttachment";
import useAdminRole from "./useAdminRole";
import useAdminGroup from "./useAdminGroup";
import useTier from "./useTier";
import usePoint from "./usePoint";
import useTransaction from "./useTransaction";
import useUser from "./useUser";
import useOrderedItems from "./useOrderedItems";

const useResource = () => {
  const accountsApi = useAccount("accounts", "account");
  const conceptsApi = useConcept("concepts", "concept");
  const featuresApi = useFeature("features", "feature");
  const groupsApi = useGroup("groups", "group");
  const groupSettingsApi = useGroupSetting("groupSettings", "groupSetting");
  const socialLinksApi = useSocialLink("socialLinks", "socialLink");
  const timeLinesApi = useTimeline("timelines", "timeline");
  const attachmentsApi = useAttachment("attachments", "attachment");
  const adminRolesApi = useAdminRole("adminRoles", "adminRole");
  const adminGroupsApi = useAdminGroup("adminGroups", "adminGroup");
  const tiersApi = useTier("tiers", "tier");
  const pointsApi = usePoint("points", "point");
  const transactionsApi = useTransaction("transactions", "transaction");
  const usersApi = useUser("guests", "guest");
  const orderedItemsApi=useOrderedItems("orderedItems","orderedItem")

  return {
    ...usersApi,
    ...transactionsApi,
    ...pointsApi,
    ...tiersApi,
    ...adminGroupsApi,
    ...adminRolesApi,
    ...accountsApi,
    ...conceptsApi,
    ...featuresApi,
    ...groupsApi,
    ...groupSettingsApi,
    ...socialLinksApi,
    ...timeLinesApi,
    ...attachmentsApi,
    ...orderedItemsApi
  };
};

export default useResource;
