export enum Directions {
  RTL = "rtl",
  LTR = "ltr",
}

export enum Languages {
  ENGLISH = "en",
  ARABIC = "ar",
}

export enum Pages {
  LOGIN = "",
  REGISTER = "register",
  VERIFY = "verify",
  FORGOT_PASSWORD = "forget-password",
  DASHBOARD = "overview",
  ACCOUNTS = "accounts",
  ADMINS = "admins",
  LANGUAGES = "languages",
  FEATURES = "features",
  PAGES = "pages",
  MEDIA = "media-library",
  MESSAGES = "messages",
  MENUS = "menus",
  POSTS = "posts",
  SETTINGS = "settings",
  SLIDES = "slides",
  COURSES = "courses",
  MODULES = "modules",
  QUIZZES = "quizzes",
  QUIZ_QUESTIONS = "quiz-questions",
  QUESTIONS = "questions",
  QUESTIONS_TYPES = "questions-types",
  GROUPS = "groups",
  GROUP_SETTINGS = "group-settings",
  INTERESTS = "interests",
  BOOKINGS = "bookings",
  BOOKINGS_LIST = "bookings-history",
  FLAGS = "flags",
  GUESTS = "guests",
  GUESTS_PROFILE = "guests-profile",
  CONCEPTS = "concepts",
  BRANCHES = "branches",
  RESERVATION_STATUS = "statuses",
  TIME_SLOTS = "time-slots",
  AREAS = "areas",
  COMMENTS = "comments",
  PLAN_ITEMS = "plan-items",
  TABLES = "tables",
  TIMELINES = "timelines",
  SERVING_AREAS = "serving-areas",
  OBJECTS = "objects",
  CUSTOMER_STATS = "customerStats",
  FLOOR_EDITOR = "floor-editor",
  SOCIAL_LINKS = "social-links",
  CALL_CENTER = "call-center",
  CALL_CENTER_SETTINGS = "call-center-settings",
  BOOKINGS_TABLES = "bookings-tables",
  RESET_PASSWORD = "reset-password",
  NOTIFICATION_SETTINGS = "notification-settings",
  NOTIFICATIONS = "notifications",
  BOOKING_CONFIRMATION = "reservations",
  MENU_ITEMS = "menu-items",
  ADMIN_ROLES = "admin-roles",
  ADMIN_GROUPS = "admin-groups",
  TIERS = "tiers",
  POINTS = "points",
  TRANSACTIONS = "transactions",
  REPORTS_DASHBOARD = "ReportsDashboard",
  ORDERED_ITEMS = "orderedItems",
}

export enum Orders {
  ASC = "asc",
  DSC = "desc",
}

export enum FormActions {
  ADD = "Add",
  EDIT = "Edit",
}

export enum QuestionTypes {
  TRUE_FALSE = "True False",
  CHOOSE = "Choose",
  CHOOSE_IMAGE = "Choose with Image",
  TEXT_IMAGE = "Text with Image",
  OPTIONS_IMAGE = "Options as Image",
  COMPLETE_IMAGE = "Complete with Image",
}

export enum TimelineActions {
  CHANGE_FACEBOOK = "Facebook link",
  CHANGE_INSTAGRAM = "Instagram link",
  CHANGE_NAME = "name",
  CHANGE_GROUP = "Group",
  CHANGE_EMAIL = "Email",
  CHANGE_VERIFICATION_STATUS = "Verify Guest",
  ADD_INTEREST = "Add Customer Interests",
  REMOVE_INTEREST = "Remove Customer Interests",
  ADD_FLAG = "Add Customer Flags",
  REMOVE_FLAG = "Remove Customer Flags",
  CHANGE_PHONE_NUMBER = "Phone Number",
  REDEEM_POINTS = "Redeem Points",
  REWARD_POINTS = "Reward Points",
  REFUND_POINTS = "Refund Points",
  WELCOME_POINTS = "Welcome Points",
}

export enum Channels {
  TELEPHONE_CHANNEL = "telephone",
  ANDROID_CHANNEL = "android",
  IPHONE_CHANNEL = "iphone",
  WEB_CHANNEL = "web",
}

export enum ResourceTypes {
  CUSTOMER = "customer",
  BOOKING = "booking",
}

export enum BookingTimelineActions {
  TIME_SLOT = "Update time slot",
  STATUS = "Update Booking Status",
  TABLE = "Update Booking Table",
  DATE = "Update Booking Date",
  GUEST_COUNT = "Update Guest Count",
  RESERVATION_EMAIL = "Send Reservation Email",
}

export enum AuthErrors {
  USER_EXISTS = "UsernameExistsException",
}

export enum AdminsGroups {
  ADMIN = "admin",
}

export enum AdminsConstants {
  ADMINS_API = "AdminQueries",
}

export enum AdminsApiPaths {
  LIST_USERS = "/listUsers",
  LIST_USERS_IN_GROUP = "/listUsersInGroup",
  LIST_GROUPS_FOR_USER = "/listGroupsForUser",
  GET_USER = "/getUser",
  LIST_GROUPS = "/listGroups",
  ADD_USER_TO_GROUP = "/addUserToGroup",
  REMOVE_USER_FROM_GROUP = "/removeUserFromGroup",
  DISABLE_USER = "/disableUser",
  ENABLE_USER = "/enableUser",
}

export enum StatuesIds {
  CANCELLED = "151a9e09-5d09-4f07-9928-90cccf46f41d",
  CLOSED = "1ca36387-69d5-4dba-be42-634b34493729",
}

export const NOTIFICATION_CHANNELS = [
  { label: "email", value: "email" },
  { label: "sms", value: "sms" },
];

export const ENCRYPTION_KEY =
  "123456dasflasdldfl789asjdflkj3fadsfsdlfds0123456";

export enum ADMIN_ROLES {
  SETTINGS = "settings_access",
  DASHBOARD = "dashboard_access",
  CUSTOMERS = "customers_access",
  EDIT_LAYOUT = "edit_layout",
  ALL_CONCEPTS = "all_concepts",
  ALL_RESERVATIONS = "reservations_full_access",
  SAME_DAY = "reservations_same_day_access",
  SACHI_PARK = "sachi_park",
  SACHI_HELIOPLIS = "sachi_heliopolis",
  REIF = "reif",
  LEXIES = "lexies",
  KAZOKU = "kazoku",
  SACHI_ALMAZA = "sachi_almaza",
  MEGUMI = "megumi",
  SHINKO = "shinko",
}

export const GLOBAL_GUEST_ID = "12345";
