import { DataStore, SortDirection } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import {
  setListing,
  setListingRushDays,
  setOperationFilters,
  setSearchText,
  setSelected,
} from "../store/ducks/transaction";
import { Transaction } from "../models";
import { HeadCell } from "../models/dataTable";
import { CreateTransactionInput } from "../models/GQL_API";
import useApp from "./useApp";
import { getDayName } from "../helpers/utils";
import useConcept from "./useConcept";
import useUser from "./useUser";
import { TransactionListingVariables } from "../models/app";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { session, showConfirm, showError } = useApp();
  const { conceptsGetName } = useConcept("concepts", "concept");
  const { guestsGetName } = useUser("guests", "guest");

  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const listingRushDays = useSelector(
    (state: any) => state[`${listingName}`]["listingRushDays"]
  );
  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );
  const selected = useSelector(
    (state: any) => state[`${listingName}`]["selected"]
  );
  const operations = useSelector(
    (state: any) => state[`${listingName}`]["operations"]
  );

  async function fetchAll() {
    try {
      const listing = await DataStore.query(
        Transaction as any,
        (transaction: any) => {
          transaction.deleted("eq", "0");
          return transaction;
        },
        {}
      );
      return listing;
    } catch {}
  }

  async function fetch(props: TransactionListingVariables) {
    try {
      const {
        customerID,
        conceptID,
        searchText,
        startIndex,
        limit,
        fromDate,
        toDate,
        conceptsSelectedFilters,
      } = props;

      const listing = await DataStore.query(
        Transaction as any,
        (transaction: any) => {
          transaction.deleted("eq", "0");
          if (customerID) transaction.customerID("eq", customerID);

          // Concepts Filter
          if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
            transaction.or((model: any) => {
              for (let filter of conceptsSelectedFilters) {
                model.conceptID("eq", filter.id);
              }
            });
          } else {
            if (conceptID) transaction.conceptID("eq", conceptID);
          }

          if (operations && operations.length > 0) {
            transaction.or((model: any) => {
              for (let filter of operations) {
                model.points_operation("eq", filter.name);
              }
            });
          }

          // searchText: Filter by check-number, employee-name, customer-name
          transaction.or((model: any) => {
            model.customerName("contains", searchText);
            model.employeeName("contains", searchText);
            model.checkNumber("contains", searchText);
          });

          if (fromDate && toDate) {
            if (fromDate === toDate) {
              transaction.and((model: any) => {
                model.createdAt("contains", fromDate);
              });
            } else {
              transaction.and((model: any) => {
                model.createdAt("ge", fromDate).createdAt("le", toDate);
              });
            }
          }

          return transaction;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      // dispatch(setListing(listing));
      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function get(resourceId: string) {
    try {
      let single: Transaction | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === resourceId);
      }

      if (single === undefined) {
        single = await DataStore.query(Transaction as any, resourceId);
      }

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function rushDays(listing: Transaction[]) {
    try {
      let sundayCounter = 0;
      let mondayCounter = 0;
      let tuesdayCounter = 0;
      let wednesdayCounter = 0;
      let thursdayCounter = 0;
      let fridayCounter = 0;
      let saturdayCounter = 0;

      for (let i = 0; i < listing.length; i++) {
        let day = getDayName(listing[i].createdAt!.split("T")[0], "en-EG");

        switch (day) {
          case "Sun":
            sundayCounter = sundayCounter + 1;
            break;
          case "Mon":
            mondayCounter = mondayCounter + 1;
            break;
          case "Tue":
            tuesdayCounter = tuesdayCounter + 1;
            break;
          case "Wed":
            wednesdayCounter = wednesdayCounter + 1;
            break;
          case "Thu":
            thursdayCounter = thursdayCounter + 1;
            break;
          case "Fri":
            fridayCounter = fridayCounter + 1;
            break;
          default:
            saturdayCounter = saturdayCounter + 1;
        }
      }

      const rushDays = [
        {
          name: "Sun",
          value: sundayCounter,
          fill: "#F9204A",
        },
        {
          name: "Mon",
          value: mondayCounter,
          fill: "#37B44E",
        },
        {
          name: "Tue",
          value: tuesdayCounter,
          fill: "#FF5215",
        },
        {
          name: "Wed",
          value: wednesdayCounter,
          fill: "#E65339",
        },
        {
          name: "Thu",
          value: thursdayCounter,
          fill: "#000000",
        },
        {
          name: "Fri",
          value: fridayCounter,
          fill: "#3C8C95",
        },
        {
          name: "Sat",
          value: saturdayCounter,
          fill: "#0088FE",
        },
      ];

      return rushDays;
    } catch (err: Error | any) {
      showError(`Error fetching transactions ${err.message || ""}`);
    }
  }

  async function create(data: any) {
    try {
      const createInput: CreateTransactionInput = {
        accountID: data.accountID,
        customerID: data.customerID,
        checkNumber: data.checkNumber,
        orderTotal: data.orderTotal,
        employeeID: data.employeeID,
        employeeName: data.employeeName,
        revenueCentreName: data.revenueCentreName,
        revenueCentreID: data.revenueCentreID,
        points: data.points,
        points_operation: data.points_operation,
        paymentStatus: data.paymentStatus,
        deleted: "0",
        createdAt: new Date().toISOString(),
        createdByID: session.sub,
        createdByName: session.name,
      };

      await DataStore.save(new Transaction(createInput as any));

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(resourceId: string, data: any) {
    try {
      const original = await get(resourceId);
      if (original) {
        await DataStore.save(
          Transaction.copyOf(original!, (updated) => {
            updated.accountID = data.accountID
              ? data.accountID
              : original!.accountID;
            updated.conceptID = data.conceptID
              ? data.conceptID
              : original!.conceptID;

            updated.customerID = data.customerID
              ? data.customerID
              : original!.customerID;
            updated.checkNumber = data.checkNumber
              ? data.checkNumber
              : original!.checkNumber;
            updated.orderTotal = data.orderTotal
              ? data.orderTotal
              : original!.orderTotal;
            updated.employeeID = data.employeeID
              ? data.employeeID
              : original!.employeeID;
            updated.employeeName = data.employeeName
              ? data.employeeName
              : original!.employeeName;
            updated.revenueCentreName = data.revenueCentreName
              ? data.revenueCentreName
              : original!.revenueCentreName;
            updated.revenueCentreID = data.revenueCentreID
              ? data.revenueCentreID
              : original!.revenueCentreID;
            updated.points = data.points ? data.points : original!.points;
            updated.points_operation = data.points_operation
              ? data.points_operation
              : original!.points_operation;
            updated.paymentStatus = data.paymentStatus
              ? data.paymentStatus
              : original!.paymentStatus;
            updated.createdAt = data.createdAt
              ? data.createdAt
              : original!.createdAt;
          })
        );
      }

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(resourceId: string) {
    try {
      const original = await get(resourceId);

      await DataStore.save(
        Transaction.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(resourceIds: any) {
    for (let resourceId of resourceIds) {
      try {
        await trash(resourceId);
      } catch (err: Error | any) {
        showError(err);
      }
    }

    dispatch(
      setListing(
        listing.filter((resource: any) => !resourceIds.has(resource.id))
      )
    );

    showConfirm(
      `${resourceIds.size} ${listingName} items has been moved to trash`
    );
  }

  async function remove(resourceId: any) {
    try {
      await DataStore.delete(resourceId);

      dispatch(
        setListing(
          listing.filter((resource: any) => resource.id !== resourceId)
        )
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      console.log(err);
      showError(err);
    }
  }

  async function exportAll(params: TransactionListingVariables) {
    const transactions = await fetch(params);

    let exportedData = [];

    for (let transaction of transactions!) {
      let row: any = { ...transaction };

      if (transaction.conceptID) {
        row.conceptID = conceptsGetName(row.conceptID);
      }
      if (transaction.customerID) {
        row.customerID = guestsGetName(row.customerID);
      }

      if (transaction.itemNames) {
        row.itemNames = transaction.itemNames.join(", ");
      }

      /* Check Payment Type */
      let type = "";
      if (row.points_operation === "Reward Points") {
        type = "Full";
      } else {
        if (parseFloat(row.orderTotal) === parseFloat(row.paidAmount)) {
          type = "Full";
        } else {
          type = `Partial ${
            row.paidAmount !== null ? "(EGP" + row.paidAmount + ")" : ""
          }`;
        }
      }
      row.paymentType = type;

      exportedData.push(row);
    }

    return exportedData;
  }

  /**
   * Fix trx payment status
   *
   * @returns void
   */
  async function fixTransactionData() {
    try {
      const transactions = await fetchAll();
      console.log({ transactions });

      if (transactions)
        for (let trx of transactions) {
          let type = "";
          if (trx.points_operation === "Reward Points") {
            type = "Full";
          } else {
            if (parseFloat(trx.orderTotal) === parseFloat(trx.paidAmount)) {
              type = "Full";
            } else {
              type = "Partial";
            }
          }

          await update(trx.id, {
            paymentStatus: type,
            createdAt: new Date(trx.createdAt).toISOString(),
          });
        }

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function addConceptID() {
    try {
      const transactions = await fetchAll();
      if (transactions)
        for (let trx of transactions) {
          await update(trx.id, {
            conceptID: "3ba82605-d8d3-49f9-aeee-aa187de7f2d3",
          });
        }

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "conceptID",
      numeric: false,
      disablePadding: false,
      label: "Concept",
    },
    {
      id: "customerID",
      numeric: false,
      disablePadding: false,
      label: "Customer",
    },
    {
      id: "checkNumber",
      numeric: false,
      disablePadding: false,
      label: "Check Num.",
    },
    {
      id: "itemNames",
      numeric: false,
      disablePadding: false,
      label: "Items",
    },
    {
      id: "orderTotal",
      numeric: false,
      disablePadding: false,
      label: "Order Total",
    },
    {
      id: "paidAmount",
      numeric: false,
      disablePadding: false,
      label: "Paid Amount",
    },
    {
      id: "points",
      numeric: false,
      disablePadding: false,
      label: "Points",
    },
    {
      id: "paymentStatus",
      numeric: false,
      disablePadding: false,
      label: "Payment Status",
    },
    {
      id: "revenueCentreName",
      numeric: false,
      disablePadding: false,
      label: "Revenue Center",
    },
    {
      id: "employeeName",
      numeric: false,
      disablePadding: false,
      label: "Employee Name",
    },
    {
      id: "points_operation",
      numeric: false,
      disablePadding: false,
      label: "Operation",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = [
    "conceptID",
    "customerID",
    "checkNumber",
    "itemNames",
    "orderTotal",
    "paidAmount",
    "points",
    "paymentStatus",
    "revenueCentreName",
    "employeeName",
    "points_operation",
  ];

  const options: any[] = [];

  for (let option of listing) {
    options.push({ label: option.name, value: option.id });
  }

  const api: any = {};

  api[`${listingName}Listing`] = listing;
  api[`${listingName}ListingRushDays`] = listingRushDays;
  api[`${listingName}Operations`] = operations;
  api[`${listingName}Options`] = options;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Model`] = Transaction as any;
  api[`${listingName}Selected`] = selected;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}FetchRushDays`] = rushDays;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}AddConceptID`] = addConceptID;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}FixTransactionData`] = fixTransactionData;
  api[`${listingName}ChangeListing`] = (listing: Transaction[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeListingRushDays`] = (listing: any[]) =>
    dispatch(setListingRushDays(listing));
  api[`${listingName}Search`] = (searchText: string) =>
    dispatch(setSearchText(searchText));
  api[`${listingName}Select`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api[`${listingName}ChangeOperationFilters`] = (operations: any[]) =>
    dispatch(setOperationFilters(operations));

  return api;
};

export default useResource;
