import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";

import ButtonLoader from "../ButtonLoader";
import useApp from "../../../hooks/useApp";
import { Pages } from "../../../constants/enums";
import useListings from "../../../hooks/useListings";

interface Props {
  resourceId: string;
  slug: string;
}

const initialState: { toDelete: string; isDeleting: boolean } = {
  toDelete: "",
  isDeleting: false,
};

const DeleteResource: React.FC<Props> = ({ resourceId, slug }) => {
  const [state, setState] = useState(initialState);
  const { hideDeletePopup } = useApp();
  const { trashResource } = useListings(slug);

  const renderTitle = () => {
    switch (slug) {
      case Pages.MEDIA:
        return "permanently media file";
      default:
        return slug.slice(0, -1);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, toDelete: event.target.value });
  };

  const handleDelete = async () => {
    setState({ ...state, isDeleting: true });

    await trashResource(resourceId);

    setState({ ...state, isDeleting: false });
    hideDeletePopup();
  };

  return (
    <Box
      sx={{
        width: "90%",
        maxWidth: 600,
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
      }}
    >
      <Box
        sx={{
          background: (theme) => theme.palette.grey[300],
          padding: "8px 16px",
          color: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">{`Your about to delete ${renderTitle()}`}</Typography>
        <CloseIcon
          aria-label="close modal"
          onClick={hideDeletePopup}
          sx={{ cursor: "pointer" }}
        />
      </Box>
      <Paper
        square
        elevation={0}
        sx={{
          border: (theme) => `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ mb: 2 }}>
            <Typography component={"span"}>
              In order to proceed with deletion type
            </Typography>
            <Typography component={"span"}>
              {" "}
              <strong>delete</strong>{" "}
            </Typography>
            <Typography component={"span"}>in field below</Typography>
          </Box>
          <TextField
            placeholder={"delete"}
            fullWidth
            size="small"
            value={state.toDelete}
            onChange={handleChange}
          />
        </Box>
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "flex-end",
            borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Button
            disableElevation
            sx={{ mr: 2 }}
            variant="contained"
            size="small"
            onClick={hideDeletePopup}
          >
            Cancel
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="error"
            size="small"
            onClick={handleDelete}
            disabled={state.toDelete !== "delete" || state.isDeleting}
            endIcon={state.isDeleting && <ButtonLoader size={20} />}
          >
            Continue
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default DeleteResource;
