import { extractSelectedCheckboxes, getDomainName } from "./../helpers/utils";
import { API, DataStore, Predicates, SortDirection } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import { setListing, setSearchText, setSelected } from "../store/ducks/account";
import { Account } from "../models";
import { HeadCell } from "../models/dataTable";
import { CreateAccountInput } from "../models/api";
import useApp from "./useApp";
import { listAccounts } from "../graphql/queries";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { session, showConfirm, showError } = useApp();
  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );

  const selected = useSelector(
    (state: any) => state[`${listingName}`]["selected"]
  );

  async function fetch(startIndex: number, limit: number) {
    try {
      const listing = await DataStore.query(
        Account as any,
        (acc: any) =>
          acc
            .deleted("eq", "0")
            .or((acc: any) =>
              acc
                .domain("contains", searchText)
                .siteTitle("contains", searchText)
                .siteAddress("contains", searchText)
            ),
        {
          page: startIndex,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      // dispatch(setListing(listing));

      return listing;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function byDomainOffline() {
    try {
      const listing = await DataStore.query(Account as any, Predicates.ALL, {
        page: 0,
        limit: 100,
        sort: (s) => s.createdAt(SortDirection.DESCENDING),
      });

      const account = listing.find(
        (acc: any) => acc.domain === getDomainName()
      );

      if (account) {
        dispatch(setSelected(account));

        return account;
      }
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function byDomainOnline(session: any) {
    try {
      const listing: any = await API.graphql({
        query: listAccounts,
        variables: {
          limit: 1000,
        },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      if (listing.data.listAccounts.items.length > 0) {
        const account = listing.data.listAccounts.items.find(
          (acc: any) => acc.domain === getDomainName()
        );
        if (account) {
          dispatch(setSelected(account));

          return account;
        }
      }
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function get(resourceId: string) {
    try {
      let single: Account | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === resourceId);
      }

      if (single === undefined) {
        single = await DataStore.query(Account as any, resourceId);
      }

      return single;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function create(data: any, stateData: any) {
    const languages: string[] = extractSelectedCheckboxes("languages_", data);
    const features: string[] = extractSelectedCheckboxes("features_", data);

    try {
      const createInput: CreateAccountInput = {
        domain: data.domain,
        siteTitle: data.siteTitle,
        tagline: data.tagline,
        description: data.description,
        siteAddress: data.siteAddress,
        defaultLanguage: data.defaultLanguage,
        status: "active",
        languages: languages,
        features: features,
        deleted: "0",
        createdAt: new Date().toISOString(),
        createdByID: session.sub,
        createdByName: session.name,
      };

      /**
       * @toDO: Add account social media links
       */

      await DataStore.save(new Account(createInput as any));

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function update(resourceId: string, data: any) {
    const languages: string[] = extractSelectedCheckboxes("languages_", data);
    const features: string[] = extractSelectedCheckboxes("features_", data);

    try {
      const original = await get(resourceId);

      await DataStore.save(
        Account.copyOf(original!, (updated) => {
          updated.domain = data.domain ? data.domain : original!.domain;
          updated.description = data.description
            ? data.description
            : original!.description;
          updated.siteAddress = data.siteAddress
            ? data.siteAddress
            : original!.siteAddress;
          updated.defaultLanguage = data.defaultLanguage
            ? data.defaultLanguage
            : original!.defaultLanguage;
          updated.languages = languages;
          updated.features = features;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function trash(resourceId: string) {
    try {
      const original = await get(resourceId);

      await DataStore.save(
        Account.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  async function bulkTrash(resourceIds: any) {
    for (let resourceId of resourceIds) {
      try {
        await trash(resourceId);
      } catch (err: Error | any) {
        showError(err);
      }
    }

    dispatch(
      setListing(
        listing.filter((resource: any) => !resourceIds.has(resource.id))
      )
    );

    showConfirm(
      `${resourceIds.size} ${listingName} items has been moved to trash`
    );
  }

  async function remove(resourceId: any) {
    try {
      await DataStore.delete(resourceId);

      dispatch(
        setListing(
          listing.filter((resource: any) => resource.id !== resourceId)
        )
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "siteTitle",
      numeric: false,
      disablePadding: false,
      label: "Site Title",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["siteTitle"];

  const options: any[] = [];

  for (let option of listing) {
    options.push({ label: option.name, value: option.id });
  }

  const api: any = {};

  api[`${listingName}Listing`] = listing;
  api[`${listingName}Options`] = options;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Model`] = Account as any;
  api[`${listingName}Selected`] = selected;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchByDomain`] = byDomainOffline;
  api[`${listingName}FetchByDomainOnline`] = byDomainOnline;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Account[]) =>
    dispatch(setListing(listing));
  api[`${listingName}Search`] = (searchText: string) =>
    dispatch(setSearchText(searchText));
  api[`${listingName}Select`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useResource;
