import { Box, Button, Paper, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useApp from "../../../hooks/useApp";
import { useEffect, useState } from "react";
import useGroupSetting from "../../../hooks/useGroupSetting";
import ButtonLoader from "../ButtonLoader";
import useUpdating from "../../../hooks/useUpdating";
import useLoading from "../../../hooks/useLoading";
import ContentLoader from "../ContentLoader";

interface Props {
  groupID: string;
  settingID: string;
}

interface StateProps {
  amount: number;
  needsDeposit: boolean;
  needsVerification: boolean;
  statusID: string;
}

const GroupSettingModal: React.FC<Props> = ({ groupID, settingID }) => {
  const initialState: StateProps = {
    needsDeposit: true,
    needsVerification: true,
    amount: 0,
    statusID: "aa42f7e6-1f36-4aa9-bbea-b1d4bcd45966",
  };
  const [state, setState] = useState(initialState);
  const { hideDeletePopup } = useApp();
  const { loading, changeLoading } = useLoading();
  const { updating, changeUpdating } = useUpdating();
  const { groupSettingsGet, groupSettingsCreate, groupSettingsUpdate } =
    useGroupSetting("groupSettings", "groupSetting");

  useEffect(() => {
    const fetch = async () => {
      const settingData = await groupSettingsGet(settingID);

      setState({
        amount: settingData.amount,
        needsDeposit: settingData.needsDeposit,
        needsVerification: settingData.needsVerification,
        statusID: settingData.statusID,
      });

      changeLoading(false);
    };

    settingID.length > 0 ? fetch() : changeLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { needsDeposit, needsVerification, amount, statusID } = state;

  const create = async () => {
    changeUpdating(true);

    const createInput: any = {
      groupID: groupID,
      isDefault: false,
      statusID,
      needsVerification,
      needsDeposit,
      amount,
      days: [],
      timeSlots: [],
    };

    await groupSettingsCreate(createInput);

    changeUpdating(false);

    hideDeletePopup();
  };

  const update = async () => {
    changeUpdating(true);

    const updateInput: any = {
      groupID: groupID,
      isDefault: false,
      statusID,
      needsVerification,
      needsDeposit,
      amount,
      days: [],
      timeSlots: [],
    };

    await groupSettingsUpdate(settingID, updateInput);

    changeUpdating(false);

    hideDeletePopup();
  };

  const handleSave = () => (settingID.length > 0 ? update() : create());

  if (loading) return <ContentLoader />;

  return (
    <Box
      sx={{
        width: "90%",
        maxWidth: 892,
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
      }}
    >
      <Box
        sx={{
          backgroundColor: "grey.300",
          padding: "8px 16px",
          color: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">
          {settingID.length === 0
            ? "New Exception Settings"
            : "Edit Exception Settings"}
        </Typography>
        <CloseIcon
          aria-label="close modal"
          onClick={hideDeletePopup}
          sx={{ cursor: "pointer" }}
        />
      </Box>
      <Paper
        square
        elevation={0}
        sx={{ border: "1px solid", borderColor: "divider" }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Select Days
          </Typography>
          <Box sx={{ ml: "-4px" }}></Box>
        </Box>
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "flex-end",
            borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Button
            disableElevation
            variant="text"
            size="small"
            onClick={hideDeletePopup}
            sx={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            disableElevation
            variant="contained"
            size="small"
            onClick={handleSave}
            sx={{ ml: 2, textTransform: "none" }}
            disabled={updating}
            endIcon={updating && <ButtonLoader />}
          >
            {`${
              settingID.length === 0 ? "Create New" : "Update"
            } Exception Setting`}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default GroupSettingModal;
