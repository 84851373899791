import { API, DataStore, SortDirection, graphqlOperation } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import {
  setBranches,
  setListing,
  setSearchText,
  setSelected,
  setFilters,
  setSelectedFilters,
} from "../store/ducks/concept";
import { Concept } from "../models";
import { HeadCell } from "../models/dataTable";
import { CreateConceptInput } from "../models/api";
import useApp from "./useApp";
import useAccount from "./useAccount";
import { listConcepts } from "../graphql/queries";
import { ConceptListingVariables } from "../models/app";

const useResource: any = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { session, showConfirm, showError } = useApp();
  const { accountsSelected } = useAccount("accounts", "account");
  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );
  const selected = useSelector(
    (state: any) => state[`${listingName}`]["selected"]
  );
  const filters = useSelector(
    (state: any) => state[`${listingName}`]["filters"]
  );
  const selectedFilters = useSelector(
    (state: any) => state[`${listingName}`]["selectedFilters"]
  );

  async function fetch(params: ConceptListingVariables) {
    try {
      const { accountID, startIndex, limit } = params;
      const listing = await DataStore.query(
        Concept as any,
        (model: any) => model.accountID("eq", accountID).deleted("eq", "0"),
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      if (listing.length > 0) {
        if (selected.length === 0) dispatch(setSelected(listing[0].id));
      }

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message);
    }
  }

  async function fetchOnline(session: any) {
    const listing: any = await API.graphql({
      query: listConcepts,
      variables: {
        limit: 1000,
      },
      authMode: session
        ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        : GRAPHQL_AUTH_MODE.AWS_IAM,
    });
    return listing.data.listConcepts.items;
  }

  async function get(resourceId: string) {
    try {
      let single: Concept | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === resourceId);
      }

      if (single === undefined) {
        single = await DataStore.query(Concept as any, resourceId);
      }

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(data: any) {
    if (accountsSelected.id.length === 0)
      throw new Error("Account must be selected");

    try {
      const createInput: CreateConceptInput = {
        accountID: accountsSelected.id,
        name: data.name,
        deleted: "0",
        createdAt: new Date().toISOString(),
        createdByID: session.sub,
        createdByName: session.name,
      };

      await DataStore.save(new Concept(createInput as any));

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(resourceId: string, data: any) {
    try {
      const original = await get(resourceId);

      await DataStore.save(
        Concept.copyOf(original!, (updated) => {
          updated.name = data.name;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(resourceId: string) {
    try {
      const original = await get(resourceId);

      await DataStore.save(
        Concept.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(resourceIds: any) {
    for (let resourceId of resourceIds) {
      try {
        await trash(resourceId);
      } catch (err: Error | any) {
        showError(err);
      }
    }

    dispatch(
      setListing(
        listing.filter((resource: any) => !resourceIds.has(resource.id))
      )
    );

    showConfirm(
      `${resourceIds.size} ${listingName} items has been moved to trash`
    );
  }

  async function remove(resourceId: any) {
    try {
      await DataStore.delete(resourceId);

      dispatch(
        setListing(
          listing.filter((resource: any) => resource.id !== resourceId)
        )
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      console.log(err);
      showError(err);
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (id: string) => {
    if (listing.length > 0) {
      const model = listing.find((model: Concept) => model.id === id);
      return model ? model.name : "";
    }

    return "";
  };

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name"];

  const options: any[] = [];

  for (let option of listing) {
    options.push({ label: option.name, value: option.id });
  }

  const api: any = {};

  api[`${listingName}Listing`] = listing;
  api[`${listingName}Filters`] = filters;
  api[`${listingName}SelectedFilters`] = selectedFilters;
  api[`${listingName}Options`] = options;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Model`] = Concept as any;
  api[`${listingName}Selected`] = selected;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchOnline`] = fetchOnline;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}ChangeListing`] = (listing: Concept[]) => {
    dispatch(setListing(listing));
    dispatch(setFilters(listing.map((model: any) => model.name)));
  };
  api[`${listingName}Search`] = (searchText: string) =>
    dispatch(setSearchText(searchText));
  api[`${listingName}Select`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api[`${listingName}SetBranches`] = (branches: any) =>
    dispatch(setBranches(branches));
  api[`${listingName}ChangeFilters`] = (filters: string[]) =>
    dispatch(setFilters(filters));
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) =>
    dispatch(setSelectedFilters(filters));

  return api;
};

export default useResource;
