import { lazy, Suspense, useCallback, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import ErrorBoundary from "./components/UI/ErrorBoundary";
import MainLoader from "./components/UI/MainLoader";
import MainModal from "./components/UI/MainModal";
import MainSnackbar from "./components/UI/MainSnackbar";
import useApp from "./hooks/useApp";
import useLoading from "./hooks/useLoading";
import useAccount from "./hooks/useAccount";
import useFeature from "./hooks/useFeature";
import useConcept from "./hooks/useConcept";
import { DataStore } from "aws-amplify";
import { ListingByAccountVariables } from "./models/app";

const Admin = lazy(() => import("./containers/Dashboard"));
const Auth = lazy(() => import("./containers/Auth"));

const App: React.FC = () => {
  const { loading, changeLoading } = useLoading();
  const { modalOpen, snackBarOpen, fetchSession, setSession, clearSession } =
    useApp();
  const { accountsFetchByDomainOnline, accountsSelected } = useAccount(
    "accounts",
    "account"
  );
  const { featuresFetchByAccount, featuresAllListing } = useFeature(
    "features",
    "feature"
  );

  const { conceptsFetch, conceptsChangeListing } = useConcept(
    "concepts",
    "concept"
  );

  const initApp = useCallback(async (session: any) => {
    try {
      setSession(session);

      const data = await Promise.all([
        accountsFetchByDomainOnline(session),
        featuresFetchByAccount(),
      ]);

      const listingByAccountParams: ListingByAccountVariables = {
        accountID: data[0].id,
        searchText: "",
        startIndex: 0,
        limit: 1000,
      };

      const concepts = await conceptsFetch(listingByAccountParams);

      setSession(session);
      conceptsChangeListing(concepts);
      changeLoading(false);
    } catch (err: Error | any) {
      clearSession();
      changeLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const getSession = async () => {
    try {
      const session = await fetchSession();

      initApp(session);
    } catch (err: Error | any) {
      sync();
    }
  };

  const sync = async () => {
    await DataStore.start();

    initApp(null);
  };

  useEffect(() => {
    getSession();

    // eslint-disable-next-line
  }, []);

  if (loading && (!accountsSelected || !featuresAllListing))
    return <MainLoader />;

  return (
    <ErrorBoundary>
      <DndProvider backend={HTML5Backend}>
        <Suspense fallback={<MainLoader />}>
          <Routes>
            <Route path="/*" element={<Auth />} />
            <Route path="dashboard/*" element={<Admin />} />
          </Routes>
        </Suspense>
      </DndProvider>
      {modalOpen && <MainModal />}
      {snackBarOpen && <MainSnackbar />}
    </ErrorBoundary>
  );
};

export default App;
