import { DataStore, SortDirection } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import {
  setListing,
  setListingStats,
  setSearchText,
  setSelected,
  setSelectedFilters,
} from "../store/ducks/tier";
import { Tier } from "../models";
import { HeadCell } from "../models/dataTable";
import { CreateTierInput } from "../models/GQL_API";
import useApp from "./useApp";
import useAccount from "./useAccount";
import useConcept from "./useConcept";
import { ListingVariables } from "../models/app";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { session, showConfirm, showError } = useApp();
  const { accountsSelected } = useAccount("accounts", "account");
  const { conceptsSelected } = useConcept("concepts", "concept");
  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const listingStats = useSelector(
    (state: any) => state[`${listingName}`]["listingStats"]
  );
  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );
  const selected = useSelector(
    (state: any) => state[`${listingName}`]["selected"]
  );
  const selectedFilters = useSelector(
    (state: any) => state[`${listingName}`]["selectedFilters"]
  );

  async function fetch(props: ListingVariables) {
    const { searchText, startIndex, limit } = props;

    try {
      const listing = await DataStore.query(
        Tier as any,
        (model: any) => {
          if (conceptsSelected) model.conceptID("eq", conceptsSelected);

          model
            .accountID("eq", accountsSelected.id)
            .deleted("eq", "0")
            .or((model: any) =>
              model.name("contains", searchText.toLowerCase())
            );

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      // dispatch(setListing(listing));

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function getStats(tiers: Tier[]) {
    let colors = [
      "#000000",
      "#e09f3e",
      "#14213d",
      "#fca311",
      "#335c67",
      "#e07a5f",
      "#9e2a2b",
      "#3d405b",
      "#81b29a",
      "#f2cc8f",
      "#717781",
    ];
    let stats: any = [];
    let i = 0;
    if (tiers)
      for (let tier of tiers) {
        stats.push({
          name: tier.name,
          totalUsers: tier.customerCount !== null ? tier.customerCount : 0,
          color: colors[i],
        });
        i++;
      }
    return stats;
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (id: string) => {
    if (listing.length > 0) {
      const model = listing.find((model: Tier) => model.id === id);

      return model ? model.name : "";
    }

    return "";
  };

  async function get(resourceId: string) {
    try {
      let single: Tier | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === resourceId);
      }

      if (single === undefined) {
        single = await DataStore.query(Tier as any, resourceId);
      }

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(data: any) {
    try {
      const createInput: CreateTierInput = {
        accountID: accountsSelected.id,
        conceptID: conceptsSelected,
        name: data.name ? data.name.toLowerCase() : "",
        description: data.description,
        imageUrl: data.imageUrl,
        starting_value: data.starting_value ? parseInt(data.starting_value) : 0,
        is_points_expire:
          data.is_points_expire !== undefined
            ? Boolean(data.is_points_expire)
            : false,
        welcome_points: data.welcome_points ? parseInt(data.welcome_points) : 0,
        points_gain_factor: data.points_gain_factor
          ? parseFloat(data.points_gain_factor)
          : 0,
        money_redeem_factor: data.money_redeem_factor
          ? parseFloat(data.money_redeem_factor)
          : 0,
        days_to_expire: data.days_to_expire ? parseInt(data.days_to_expire) : 0,
        discount_rate: data.discount_rate ? parseInt(data.discount_rate) : 0,
        discount_simphony_id: data.discount_simphony_id,
        customerCount: 0,

        deleted: "0",
        createdAt: new Date().toISOString(),
        createdByID: session.sub,
        createdByName: session.name,
      };

      await DataStore.save(new Tier(createInput as any));

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
    }
  }

  async function update(resourceId: string, data: any) {
    try {
      const original = await get(resourceId);

      await DataStore.save(
        Tier.copyOf(original!, (updated) => {
          updated.name = data.name ? data.name.toLowerCase() : original!.name;
          updated.description = data.description
            ? data.description
            : original!.description;
          updated.imageUrl = data.imageUrl ? data.imageUrl : original!.imageUrl;
          updated.starting_value = data.starting_value
            ? parseInt(data.starting_value)
            : original!.starting_value;
          updated.is_points_expire =
            data.is_points_expire !== undefined
              ? Boolean(data.is_points_expire)
              : original!.is_points_expire;
          updated.welcome_points = data.welcome_points
            ? parseInt(data.welcome_points)
            : original!.welcome_points;
          updated.points_gain_factor = data.points_gain_factor
            ? parseFloat(data.points_gain_factor)
            : original!.points_gain_factor;
          updated.money_redeem_factor = data.money_redeem_factor
            ? parseFloat(data.money_redeem_factor)
            : original!.money_redeem_factor;
          updated.days_to_expire = data.days_to_expire
            ? parseInt(data.days_to_expire)
            : original!.days_to_expire;
          updated.discount_rate = data.discount_rate
            ? parseInt(data.discount_rate)
            : original!.discount_rate;
          updated.discount_simphony_id = data.discount_simphony_id
            ? data.discount_simphony_id
            : original!.discount_simphony_id;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error Occurred"
      );
    }
  }

  async function trash(resourceId: string) {
    try {
      const original = await get(resourceId);

      await DataStore.save(
        Tier.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(resourceIds: any) {
    for (let resourceId of resourceIds) {
      try {
        await trash(resourceId);
      } catch (err: Error | any) {
        showError(err);
      }
    }

    dispatch(
      setListing(
        listing.filter((resource: any) => !resourceIds.has(resource.id))
      )
    );

    showConfirm(
      `${resourceIds.size} ${listingName} items has been moved to trash`
    );
  }

  async function remove(resourceId: any) {
    try {
      await DataStore.delete(resourceId);

      dispatch(
        setListing(
          listing.filter((resource: any) => resource.id !== resourceId)
        )
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      console.log(err);
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name", "description"];

  const options: any[] = [];

  for (let option of listing) {
    options.push({ label: option.name, value: option.id });
  }

  const api: any = {};

  api[`${listingName}Listing`] = listing;
  api[`${listingName}ListingStats`] = listingStats;
  api[`${listingName}Options`] = options;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Model`] = Tier as any;
  api[`${listingName}Selected`] = selected;
  api[`${listingName}SelectedFilters`] = selectedFilters;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}GetStats`] = getStats;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Tier[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeListingStats`] = (listingStats: any[]) =>
    dispatch(setListingStats(listingStats));
  api[`${listingName}Search`] = (searchText: string) =>
    dispatch(setSearchText(searchText));
  api[`${listingName}Select`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) =>
    dispatch(setSelectedFilters(filters));

  return api;
};

export default useResource;
