// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { User, EmailVerification, Account, SocialLink, Feature, Language, Media, Attachment, Concept, Group, GroupSetting, Timeline, AdminRole, AdminGroup, Point, Tier, Transaction, OrderedItems, TransactionItem } = initSchema(schema);

export {
  User,
  EmailVerification,
  Account,
  SocialLink,
  Feature,
  Language,
  Media,
  Attachment,
  Concept,
  Group,
  GroupSetting,
  Timeline,
  AdminRole,
  AdminGroup,
  Point,
  Tier,
  Transaction,
  OrderedItems,
  TransactionItem
};