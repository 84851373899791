// actions
const SET = "transaction/SET";
const SET_RUSH_DAYS = "transaction/SET_RUSH_DAYS";
const SET_SEARCH_TEXT = "transaction/SET_SEARCH_TEXT";
const SET_SELECTED = "transaction/SET_SELECTED";
const SET_OPERATION_FILTERS = "transactions/SET_OPERATION_FILTERS";

const DEFAULT_STATE = {
  listing: [],
  listingRushDays: [],
  searchText: "",
  selected: "",
  operations: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_RUSH_DAYS:
      return Object.assign({}, state, {
        listingRushDays: action.listingRushDays,
      });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_SELECTED:
      return Object.assign({}, state, { conceptsSelected: action.concept });
    case SET_OPERATION_FILTERS:
      return Object.assign({}, state, { operations: action.operations });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setListingRushDays = (listingRushDays: any[]) => ({
  listingRushDays,
  type: SET_RUSH_DAYS,
});

export const setSearchText = (searchText: string) => ({
  searchText,
  type: SET_SEARCH_TEXT,
});

export const setSelected = (single: any) => ({ single, type: SET_SELECTED });

export const setOperationFilters = (operations: any[]) => ({
  operations,
  type: SET_OPERATION_FILTERS,
});
