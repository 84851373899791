/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      total_points
      points_history
      tier
      tier_name
      points_balance
      total_spending
      orders_count
      deleted
      createdAt
      createdByID
      createdByName
      conceptID
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        total_points
        points_history
        tier
        tier_name
        points_balance
        total_spending
        orders_count
        deleted
        createdAt
        createdByID
        createdByName
        conceptID
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        total_points
        points_history
        tier
        tier_name
        points_balance
        total_spending
        orders_count
        deleted
        createdAt
        createdByID
        createdByName
        conceptID
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const accountUsers = /* GraphQL */ `
  query AccountUsers(
    $accountID: String!
    $createdAtDeleted: ModelUserByAccountCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountUsers(
      accountID: $accountID
      createdAtDeleted: $createdAtDeleted
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        total_points
        points_history
        tier
        tier_name
        points_balance
        total_spending
        orders_count
        deleted
        createdAt
        createdByID
        createdByName
        conceptID
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEmailVerification = /* GraphQL */ `
  query GetEmailVerification($id: ID!) {
    getEmailVerification(id: $id) {
      id
      accountID
      name
      email
      verification_code
      createdAt
      deleted
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listEmailVerifications = /* GraphQL */ `
  query ListEmailVerifications(
    $filter: ModelEmailVerificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailVerifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        name
        email
        verification_code
        createdAt
        deleted
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEmailVerifications = /* GraphQL */ `
  query SyncEmailVerifications(
    $filter: ModelEmailVerificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEmailVerifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        name
        email
        verification_code
        createdAt
        deleted
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const accountVerifications = /* GraphQL */ `
  query AccountVerifications(
    $accountID: String!
    $createdAtDeleted: ModelEmailVerificationByAccountCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmailVerificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountVerifications(
      accountID: $accountID
      createdAtDeleted: $createdAtDeleted
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        name
        email
        verification_code
        createdAt
        deleted
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      logo {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      domain
      siteTitle
      guestsCount
      tagline
      description
      siteAddress
      defaultLanguage
      languages
      features
      status
      socialLinks
      deleted
      createdAt
      createdByID
      createdByName
      callCenterEmployeesIds
      updatedAt
      _version
      _deleted
      _lastChangedAt
      accountLogoId
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        logo {
          id
          mediaID
          fileUrl
          filename
          filetype
          fileSize
          alternativeText
          caption
          description
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        domain
        siteTitle
        guestsCount
        tagline
        description
        siteAddress
        defaultLanguage
        languages
        features
        status
        socialLinks
        deleted
        createdAt
        createdByID
        createdByName
        callCenterEmployeesIds
        updatedAt
        _version
        _deleted
        _lastChangedAt
        accountLogoId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAccounts = /* GraphQL */ `
  query SyncAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        logo {
          id
          mediaID
          fileUrl
          filename
          filetype
          fileSize
          alternativeText
          caption
          description
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        domain
        siteTitle
        guestsCount
        tagline
        description
        siteAddress
        defaultLanguage
        languages
        features
        status
        socialLinks
        deleted
        createdAt
        createdByID
        createdByName
        callCenterEmployeesIds
        updatedAt
        _version
        _deleted
        _lastChangedAt
        accountLogoId
      }
      nextToken
      startedAt
    }
  }
`;
export const accountsByDomain = /* GraphQL */ `
  query AccountsByDomain(
    $domain: String!
    $createdAtDeleted: ModelAccountByDomainCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountsByDomain(
      domain: $domain
      createdAtDeleted: $createdAtDeleted
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        logo {
          id
          mediaID
          fileUrl
          filename
          filetype
          fileSize
          alternativeText
          caption
          description
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        domain
        siteTitle
        guestsCount
        tagline
        description
        siteAddress
        defaultLanguage
        languages
        features
        status
        socialLinks
        deleted
        createdAt
        createdByID
        createdByName
        callCenterEmployeesIds
        updatedAt
        _version
        _deleted
        _lastChangedAt
        accountLogoId
      }
      nextToken
      startedAt
    }
  }
`;
export const getSocialLink = /* GraphQL */ `
  query GetSocialLink($id: ID!) {
    getSocialLink(id: $id) {
      id
      name
      icon
      slug
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSocialLinks = /* GraphQL */ `
  query ListSocialLinks(
    $filter: ModelSocialLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSocialLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        icon
        slug
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSocialLinks = /* GraphQL */ `
  query SyncSocialLinks(
    $filter: ModelSocialLinkFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSocialLinks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        icon
        slug
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const activeLinks = /* GraphQL */ `
  query ActiveLinks(
    $name: String!
    $createdAtDeleted: ModelSocialLinkByNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSocialLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeLinks(
      name: $name
      createdAtDeleted: $createdAtDeleted
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        icon
        slug
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFeature = /* GraphQL */ `
  query GetFeature($id: ID!) {
    getFeature(id: $id) {
      id
      name
      icon
      slug
      precedence
      parent
      private
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFeatures = /* GraphQL */ `
  query ListFeatures(
    $filter: ModelFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeatures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        icon
        slug
        precedence
        parent
        private
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFeatures = /* GraphQL */ `
  query SyncFeatures(
    $filter: ModelFeatureFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFeatures(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        icon
        slug
        precedence
        parent
        private
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const searchFeatures = /* GraphQL */ `
  query SearchFeatures(
    $name: String!
    $createdAtDeleted: ModelFeatureByNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchFeatures(
      name: $name
      createdAtDeleted: $createdAtDeleted
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        icon
        slug
        precedence
        parent
        private
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const activeFeatures = /* GraphQL */ `
  query ActiveFeatures(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeFeatures(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        icon
        slug
        precedence
        parent
        private
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLanguage = /* GraphQL */ `
  query GetLanguage($id: ID!) {
    getLanguage(id: $id) {
      id
      name
      code
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLanguages = /* GraphQL */ `
  query ListLanguages(
    $filter: ModelLanguageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLanguages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        code
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLanguages = /* GraphQL */ `
  query SyncLanguages(
    $filter: ModelLanguageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLanguages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        code
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const activeLanguages = /* GraphQL */ `
  query ActiveLanguages(
    $name: String!
    $createdAtDeleted: ModelLanguageByNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLanguageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeLanguages(
      name: $name
      createdAtDeleted: $createdAtDeleted
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        code
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMedia = /* GraphQL */ `
  query GetMedia($id: ID!) {
    getMedia(id: $id) {
      id
      accountID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      attachments
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMedia = /* GraphQL */ `
  query ListMedia(
    $filter: ModelMediaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedia(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        attachments
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMedia = /* GraphQL */ `
  query SyncMedia(
    $filter: ModelMediaFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMedia(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        attachments
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAttachment = /* GraphQL */ `
  query GetAttachment($id: ID!) {
    getAttachment(id: $id) {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAttachments = /* GraphQL */ `
  query ListAttachments(
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAttachments = /* GraphQL */ `
  query SyncAttachments(
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAttachments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getConcept = /* GraphQL */ `
  query GetConcept($id: ID!) {
    getConcept(id: $id) {
      id
      accountID
      name
      kiosks
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listConcepts = /* GraphQL */ `
  query ListConcepts(
    $filter: ModelConceptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        name
        kiosks
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncConcepts = /* GraphQL */ `
  query SyncConcepts(
    $filter: ModelConceptFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncConcepts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        name
        kiosks
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const accountConcepts = /* GraphQL */ `
  query AccountConcepts(
    $accountID: String!
    $createdAtDeleted: ModelConceptByAccountCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConceptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountConcepts(
      accountID: $accountID
      createdAtDeleted: $createdAtDeleted
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        name
        kiosks
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      accountID
      name
      description
      color
      requireApproval
      status
      agents
      groupSettings
      numToUpgrade
      numToDowngrade
      upgradeGroup
      downgradeGroup
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        name
        description
        color
        requireApproval
        status
        agents
        groupSettings
        numToUpgrade
        numToDowngrade
        upgradeGroup
        downgradeGroup
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGroups = /* GraphQL */ `
  query SyncGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        name
        description
        color
        requireApproval
        status
        agents
        groupSettings
        numToUpgrade
        numToDowngrade
        upgradeGroup
        downgradeGroup
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const accountGroups = /* GraphQL */ `
  query AccountGroups(
    $accountID: String!
    $createdAtDeleted: ModelGroupByAccountCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountGroups(
      accountID: $accountID
      createdAtDeleted: $createdAtDeleted
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        name
        description
        color
        requireApproval
        status
        agents
        groupSettings
        numToUpgrade
        numToDowngrade
        upgradeGroup
        downgradeGroup
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const activeGroups = /* GraphQL */ `
  query ActiveGroups(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeGroups(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        name
        description
        color
        requireApproval
        status
        agents
        groupSettings
        numToUpgrade
        numToDowngrade
        upgradeGroup
        downgradeGroup
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGroupSetting = /* GraphQL */ `
  query GetGroupSetting($id: ID!) {
    getGroupSetting(id: $id) {
      id
      groupID
      statusID
      needsVerification
      needsDeposit
      isDefault
      amount
      days
      timeSlots
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listGroupSettings = /* GraphQL */ `
  query ListGroupSettings(
    $filter: ModelGroupSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupID
        statusID
        needsVerification
        needsDeposit
        isDefault
        amount
        days
        timeSlots
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGroupSettings = /* GraphQL */ `
  query SyncGroupSettings(
    $filter: ModelGroupSettingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGroupSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groupID
        statusID
        needsVerification
        needsDeposit
        isDefault
        amount
        days
        timeSlots
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const statusSettings = /* GraphQL */ `
  query StatusSettings(
    $statusID: ID!
    $createdAtDeleted: ModelGroupSettingByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    statusSettings(
      statusID: $statusID
      createdAtDeleted: $createdAtDeleted
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupID
        statusID
        needsVerification
        needsDeposit
        isDefault
        amount
        days
        timeSlots
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const activeGroupSettings = /* GraphQL */ `
  query ActiveGroupSettings(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeGroupSettings(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupID
        statusID
        needsVerification
        needsDeposit
        isDefault
        amount
        days
        timeSlots
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTimeline = /* GraphQL */ `
  query GetTimeline($id: ID!) {
    getTimeline(id: $id) {
      id
      actionName
      oldStatus
      newStatus
      bookingId
      customerId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTimelines = /* GraphQL */ `
  query ListTimelines(
    $filter: ModelTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimelines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        actionName
        oldStatus
        newStatus
        bookingId
        customerId
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTimelines = /* GraphQL */ `
  query SyncTimelines(
    $filter: ModelTimelineFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTimelines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        actionName
        oldStatus
        newStatus
        bookingId
        customerId
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const activeTimelines = /* GraphQL */ `
  query ActiveTimelines(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeTimelines(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        actionName
        oldStatus
        newStatus
        bookingId
        customerId
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAdminRole = /* GraphQL */ `
  query GetAdminRole($id: ID!) {
    getAdminRole(id: $id) {
      id
      name
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAdminRoles = /* GraphQL */ `
  query ListAdminRoles(
    $filter: ModelAdminRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAdminRoles = /* GraphQL */ `
  query SyncAdminRoles(
    $filter: ModelAdminRoleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminRoles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const activeRoles = /* GraphQL */ `
  query ActiveRoles(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeRoles(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAdminGroup = /* GraphQL */ `
  query GetAdminGroup($id: ID!) {
    getAdminGroup(id: $id) {
      id
      name
      description
      roles
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAdminGroups = /* GraphQL */ `
  query ListAdminGroups(
    $filter: ModelAdminGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        roles
        users
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAdminGroups = /* GraphQL */ `
  query SyncAdminGroups(
    $filter: ModelAdminGroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        roles
        users
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const activeAdminGroups = /* GraphQL */ `
  query ActiveAdminGroups(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeAdminGroups(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        roles
        users
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPoint = /* GraphQL */ `
  query GetPoint($id: ID!) {
    getPoint(id: $id) {
      id
      accountID
      amount
      expiry_date
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPoints = /* GraphQL */ `
  query ListPoints(
    $filter: ModelPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPoints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        amount
        expiry_date
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPoints = /* GraphQL */ `
  query SyncPoints(
    $filter: ModelPointFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPoints(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        amount
        expiry_date
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const activePoints = /* GraphQL */ `
  query ActivePoints(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activePoints(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        amount
        expiry_date
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTier = /* GraphQL */ `
  query GetTier($id: ID!) {
    getTier(id: $id) {
      id
      accountID
      conceptID
      name
      description
      imageUrl
      starting_value
      is_points_expire
      welcome_points
      points_gain_factor
      money_redeem_factor
      days_to_expire
      discount_rate
      discount_simphony_id
      customerCount
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTiers = /* GraphQL */ `
  query ListTiers(
    $filter: ModelTierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTiers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        conceptID
        name
        description
        imageUrl
        starting_value
        is_points_expire
        welcome_points
        points_gain_factor
        money_redeem_factor
        days_to_expire
        discount_rate
        discount_simphony_id
        customerCount
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTiers = /* GraphQL */ `
  query SyncTiers(
    $filter: ModelTierFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTiers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        conceptID
        name
        description
        imageUrl
        starting_value
        is_points_expire
        welcome_points
        points_gain_factor
        money_redeem_factor
        days_to_expire
        discount_rate
        discount_simphony_id
        customerCount
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const activeTiers = /* GraphQL */ `
  query ActiveTiers(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeTiers(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        conceptID
        name
        description
        imageUrl
        starting_value
        is_points_expire
        welcome_points
        points_gain_factor
        money_redeem_factor
        days_to_expire
        discount_rate
        discount_simphony_id
        customerCount
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      accountID
      conceptID
      customerID
      customerName
      checkNumber
      orderTotal
      paidAmount
      employeeID
      employeeName
      revenueCentreName
      revenueCentreID
      points
      points_operation
      refundTrx
      refundAmount
      paymentStatus
      itemNames
      itemCounts
      orderedItems {
        symphonyID
        name
        quantity
        unitPrice
        totalPrice
        refunded
      }
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        conceptID
        customerID
        customerName
        checkNumber
        orderTotal
        paidAmount
        employeeID
        employeeName
        revenueCentreName
        revenueCentreID
        points
        points_operation
        refundTrx
        refundAmount
        paymentStatus
        itemNames
        itemCounts
        orderedItems {
          symphonyID
          name
          quantity
          unitPrice
          totalPrice
          refunded
        }
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTransactions = /* GraphQL */ `
  query SyncTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        conceptID
        customerID
        customerName
        checkNumber
        orderTotal
        paidAmount
        employeeID
        employeeName
        revenueCentreName
        revenueCentreID
        points
        points_operation
        refundTrx
        refundAmount
        paymentStatus
        itemNames
        itemCounts
        orderedItems {
          symphonyID
          name
          quantity
          unitPrice
          totalPrice
          refunded
        }
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const activeTransactions = /* GraphQL */ `
  query ActiveTransactions(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeTransactions(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        conceptID
        customerID
        customerName
        checkNumber
        orderTotal
        paidAmount
        employeeID
        employeeName
        revenueCentreName
        revenueCentreID
        points
        points_operation
        refundTrx
        refundAmount
        paymentStatus
        itemNames
        itemCounts
        orderedItems {
          symphonyID
          name
          quantity
          unitPrice
          totalPrice
          refunded
        }
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrderedItems = /* GraphQL */ `
  query GetOrderedItems($id: ID!) {
    getOrderedItems(id: $id) {
      id
      userID
      itemName
      conceptID
      count
      isGlobal
      createdAt
      createdByName
      deleted
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listOrderedItems = /* GraphQL */ `
  query ListOrderedItems(
    $filter: ModelOrderedItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderedItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        itemName
        conceptID
        count
        isGlobal
        createdAt
        createdByName
        deleted
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrderedItems = /* GraphQL */ `
  query SyncOrderedItems(
    $filter: ModelOrderedItemsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrderedItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        itemName
        conceptID
        count
        isGlobal
        createdAt
        createdByName
        deleted
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const activeOrders = /* GraphQL */ `
  query ActiveOrders(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderedItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeOrders(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        itemName
        conceptID
        count
        isGlobal
        createdAt
        createdByName
        deleted
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
