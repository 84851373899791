import { Pages } from "../constants/enums";
import useLocalization from "./useLocalization";
import useLanguage from "./useLanguage";
import useFeature from "./useFeature";
import useGroup from "./useGroup";
import useAdmin from "./useAdmin";
import useAdminRole from "./useAdminRole";
import useTier from "./useTier";

const useFormFields = (slug: string, model?: any) => {
  const { strings } = useLocalization();
  const { languagesOptions } = useLanguage("languages", "language");
  const { featuresOptions } = useFeature("features", "feature");
  const { groupsOptions } = useGroup("groups", "group");
  const { adminsCognitoGroups, adminsOptions } = useAdmin("admins", "admin");
  const { adminRolesOptions } = useAdminRole("adminRoles", "adminRole");
  const { tiersOptions } = useTier("tiers", "tier");

  const loginFields = () => [
    {
      name: "email",
      label: strings.email,
      type: "textField",
      autoFocus: true,
      defaultValue: "",
    },
    {
      name: "password",
      label: strings.password,
      type: "password",
      autoFocus: false,
      defaultValue: "",
    },
  ];

  const registerFields = () => [
    {
      name: "name",
      label: "Full Name",
      type: "textField",
      autoFocus: true,
      defaultValue: "",
    },
    {
      name: "email",
      label: strings.email,
      type: "textField",
      autoFocus: false,
      defaultValue: "",
    },
    {
      name: "password",
      label: strings.password,
      type: "password",
      autoFocus: false,
      defaultValue: "",
    },
  ];
  const adminFields = () => [
    {
      name: "name",
      label: "Full Name",
      type: "textField",
      autoFocus: true,
      defaultValue: "",
    },
    {
      name: "email",
      label: strings.email,
      type: "textField",
      autoFocus: false,
      defaultValue: "",
    },
    {
      name: "password",
      label: strings.password,
      type: "password",
      autoFocus: false,
      defaultValue: "",
    },
    {
      name: "group",
      label: "Admin Group",
      type: "select",
      autoFocus: false,
      defaultValue: adminsCognitoGroups[0].value,
      options: adminsCognitoGroups,
    },
  ];

  const forgetPasswordFields = () => [
    {
      name: "email",
      label: strings.email,
      type: "textField",
      autoFocus: true,
      defaultValue: "",
    },
  ];
  const resetPasswordFields = () => [
    {
      name: "email",
      label: strings.email,
      type: "textField",
      autoFocus: true,
      defaultValue: "",
    },
    {
      name: "code",
      label: strings.code,
      type: "textField",
      autoFocus: false,
      defaultValue: "",
    },
    {
      name: "newPassword",
      label: strings.password,
      type: "password",
      autoFocus: false,
      defaultValue: "",
    },
  ];

  const accountFields = () => [
    {
      name: "domain",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.domain : "",
    },
    {
      name: "siteTitle",
      label: "Site Title",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.siteTitle : "",
    },
    {
      name: "tagline",
      label: "Tagline",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.tagline : "",
    },
    {
      name: "description",
      label: "Description",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.description : "",
    },
    {
      name: "siteAddress",
      label: "Site Address",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.siteAddress : "",
    },
    {
      name: "defaultLanguage",
      label: "Default Language",
      type: "select",
      autoFocus: false,
      defaultValue: model ? model.defaultLanguage : languagesOptions[0].value,
      options: languagesOptions,
    },
  ];

  const adminRoleFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "description",
      label: "Description",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.description : "",
    },
  ];

  const adminGroupsFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "description",
      label: "Description",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.description : "",
    },
  ];

  const userFields = () => [
    {
      name: "given_name",
      label: "First Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.given_name : "",
    },
    {
      name: "family_name",
      label: "Last Name",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.family_name : "",
    },
    {
      name: "nickname",
      label: "Nickname",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.nickname : "",
    },
    {
      name: "address",
      label: "Address",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.address : "",
    },
    {
      name: "phone_number",
      label: "Phone Number",
      type: "phoneNumber",
      autoFocus: false,
      defaultValue: model ? model.phone_number : "",
    },
    {
      name: "birthdate",
      label: "Birth date",
      type: "date",
      autoFocus: false,
      defaultValue: model ? model.birthdate : "",
    },
    {
      name: "email",
      label: "Email",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.email : "",
    },
    {
      name: "group",
      label: "Group",
      type: "select",
      autoFocus: false,
      defaultValue: model
        ? model.group
        : groupsOptions && groupsOptions.length > 0
        ? groupsOptions[0].value
        : "",
      options: groupsOptions,
    },
    {
      name: "total_points",
      label: "Total Points",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.total_points : "",
    },
    {
      name: "tier",
      label: "Tier",
      type: "select",
      autoFocus: false,
      defaultValue: model
        ? model.tier
        : tiersOptions && tiersOptions.length > 0
        ? tiersOptions[0].value
        : "",
      options: tiersOptions,
    },
    {
      name: "website",
      label: "Website URL",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.website : "",
    },
    {
      name: "facebook",
      label: "Facebook URL",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.facebook : "",
    },
    {
      name: "instagram",
      label: "Instagram URL",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.instagram : "",
    },
  ];

  const conceptFields = () => [
    {
      name: "name",
      label: "Concept Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
  ];

  const featureFields = () => [
    {
      name: "precedence",
      label: "Precedence",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.precedence : "",
    },
    {
      name: "name",
      label: "Feature Name",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.name : "",
    },
    {
      name: "icon",
      label: "Feature Icon",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.icon : "",
    },
    {
      name: "slug",
      label: "Feature Slug",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.slug : "",
    },
    {
      name: "parent",
      label: "Parent",
      type: "select",
      autoFocus: false,
      defaultValue: model
        ? model.parent
          ? model.parent
          : featuresOptions[0].value
        : featuresOptions[0].value,
      options: featuresOptions,
    },
    {
      name: "private",
      label: "Private",
      type: "checkbox",
      autoFocus: false,
      defaultValue: model ? model.private : false,
    },
  ];

  const languageFields = () => [
    {
      name: "name",
      label: "Language Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "code",
      label: "Language Code",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.code : "",
    },
  ];

  const socialLinkFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "icon",
      label: "Icon",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.code : "",
    },
    {
      name: "slug",
      label: "URL",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.code : "",
    },
  ];

  const groupFields = (numToUpgradeWatch: any, numToDowngradeWatch: any) => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "description",
      label: "Description",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.description : "",
    },
    {
      name: "numToUpgrade",
      label: 'Number of "Check In" to upgrade group',
      type: "number",
      autoFocus: false,
      defaultValue: model
        ? model.numToUpgrade
          ? model.numToUpgrade.toString()
          : "0"
        : "0",
    },
    {
      name: "upgradeGroup",
      label: "Upgrade Group",
      type: "select",
      autoFocus: false,
      defaultValue: model
        ? model.upgradeGroup
          ? model.upgradeGroup
          : groupsOptions[0].value
        : "-1",
      options: groupsOptions,
      disabled: numToUpgradeWatch === "0",
    },
    {
      name: "numToDowngrade",
      label: 'Number of "No Show" to upgrade group',
      type: "number",
      autoFocus: false,
      defaultValue: model
        ? model.numToDowngrade
          ? model.numToDowngrade.toString()
          : "0"
        : "0",
    },
    {
      name: "downgradeGroup",
      label: "Downgrade Group",
      type: "select",
      autoFocus: false,
      defaultValue: model
        ? model.downgradeGroup
          ? model.downgradeGroup
          : groupsOptions[0].value
        : "-1",
      options: groupsOptions,
      disabled: numToDowngradeWatch === "0",
    },
    {
      name: "requireApproval",
      label: "Require Approval",
      type: "checkbox",
      autoFocus: false,
      defaultChecked: model ? model.requireApproval : false,
    },
  ];
  const tierFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "description",
      label: "Description",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.description : "",
    },
    {
      name: "imageUrl",
      label: "Image URL",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.imageUrl : "",
    },
    {
      name: "starting_value",
      label: "Starting Value",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.starting_value : "",
    },
    {
      name: "welcome_points",
      label: "Welcome Points",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.welcome_points : "",
    },
    {
      name: "points_gain_factor",
      label: 'Each "X" EGP Will Earn 1 Point',
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.points_gain_factor : "",
    },
    {
      name: "money_redeem_factor",
      label: 'Each "X" Point Will Redeem 1 EGP',
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.money_redeem_factor : "",
    },
    {
      name: "days_to_expire",
      label: "Days To Expire",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.days_to_expire : "",
    },
    {
      name: "discount_rate",
      label: "Discount Rate",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.discount_rate : "",
    },
    {
      name: "discount_simphony_id",
      label: "Discount SimphonyID",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.discount_simphony_id : "",
    },
    {
      name: "is_points_expire",
      label: "Is Points Expire",
      type: "checkbox",
      autoFocus: false,
      defaultChecked: model ? model.is_points_expire : false,
    },
  ];

  const groupSettingFields = () => [
    {
      name: "groupID",
      label: "Group",
      type: "select",
      autoFocus: true,
      defaultValue: model
        ? model.groupID
          ? model.groupID
          : groupsOptions[0].value
        : "-1",
      options: groupsOptions,
    },
    {
      name: "needsVerification",
      label: "Needs Verification",
      type: "checkbox",
      autoFocus: false,
      defaultValue: model ? model.needsVerification : false,
    },
    {
      name: "needsDeposit",
      label: "Needs Deposit",
      type: "checkbox",
      autoFocus: false,
      defaultValue: model ? model.needsDeposit : false,
    },
    {
      name: "isDefault",
      label: "Is Default",
      type: "checkbox",
      autoFocus: false,
      defaultValue: model ? model.isDefault : false,
    },
    {
      name: "amount",
      label: "Needs Deposit",
      type: "number",
      autoFocus: false,
      defaultValue: model ? model.amount : "0",
    },
  ];

  const accountCheckboxes = () => [
    {
      name: "languages",
      label: "Languages",
      type: "checkboxes",
      autoFocus: false,
      defaultValue: model ? new Set(model.languages) : new Set(),
      options: languagesOptions,
    },
    {
      name: "features",
      label: "Features",
      type: "checkboxes",
      autoFocus: false,
      defaultValue: model ? new Set(model.features) : new Set(),
      options: featuresOptions,
    },
  ];

  const adminGroupsCheckboxes = () => [
    {
      name: "roles",
      label: "Admin Roles",
      type: "checkboxes",
      autoFocus: false,
      defaultValue: model ? new Set(model.roles) : new Set(),
      options: adminRolesOptions,
    },
    {
      name: "users",
      label: "Admin Users",
      type: "checkboxes",
      autoFocus: false,
      defaultValue: model ? new Set(model.users) : new Set(),
      options: adminsOptions,
    },
  ];

  const getFormFields = (
    numToUpgradeWatch?: any,
    numToDowngradeWatch?: any,
    channelsWatch?: any
  ) => {
    switch (slug) {
      case Pages.LOGIN:
        return loginFields();
      case Pages.REGISTER:
        return registerFields();
      case Pages.ADMINS:
        return adminFields();
      case Pages.FORGOT_PASSWORD:
        return forgetPasswordFields();
      case Pages.RESET_PASSWORD:
        return resetPasswordFields();
      case Pages.ACCOUNTS:
        return accountFields();
      case Pages.TIERS:
        return tierFields();
      case Pages.CONCEPTS:
        return conceptFields();
      case Pages.FEATURES:
        return featureFields();
      case Pages.LANGUAGES:
        return languageFields();
      case Pages.SOCIAL_LINKS:
        return socialLinkFields();
      case Pages.GROUPS:
        return groupFields(numToUpgradeWatch, numToDowngradeWatch);
      case Pages.GROUP_SETTINGS:
        return groupSettingFields();
      case Pages.GUESTS:
        return userFields();
      case Pages.ADMIN_ROLES:
        return adminRoleFields();
      case Pages.ADMIN_GROUPS:
        return adminGroupsFields();
      default:
        return [];
    }
  };

  const getCheckboxesFields = () => {
    switch (slug) {
      case Pages.ACCOUNTS:
        return accountCheckboxes();
      case Pages.ADMIN_GROUPS:
        return adminGroupsCheckboxes();
      default:
        return [];
    }
  };

  return {
    getFormFields,
    getCheckboxesFields,
  };
};

export default useFormFields;
