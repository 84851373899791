import { DataStore, SortDirection } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilters,
  setListing,
  setSearchText,
  setSelected,
  setSelectedFilters,
} from "../store/ducks/group";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import useAccount from "./useAccount";
import { Group } from "../models";
import { CreateGroupInput } from "../models/api";
import useGroupSetting from "./useGroupSetting";
import { ListingVariables } from "../models/app";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { session, showConfirm, showError } = useApp();
  const { accountsSelected } = useAccount("accounts", "account");
  const { groupSettingsCreate } = useGroupSetting(
    "groupSettings",
    "groupSetting"
  );
  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );
  const selected = useSelector(
    (state: any) => state[`${listingName}`]["selected"]
  );
  const filters = useSelector(
    (state: any) => state[`${listingName}`]["filters"]
  );
  const selectedFilters = useSelector(
    (state: any) => state[`${listingName}`]["selectedFilters"]
  );

  async function fetch(props: ListingVariables) {
    const { searchText, startIndex, limit } = props;

    try {
      const listing = await DataStore.query(
        Group as any,
        (model: any) => {
          model.accountID("eq", accountsSelected.id).deleted("eq", "0");

          // searchText: Filter by group name
          if (searchText !== undefined && searchText.length > 0)
            model.or((model: any) => {
              model.name("contains", searchText.toLowerCase());
            });

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      // dispatch(setListing(listing));
      // dispatch(setFilters(listing.map((model: any) => model.name)));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message);
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (id: string) => {
    if (listing.length > 0) {
      const model = listing.find((model: Group) => model.id === id);

      return model ? model.name : "";
    }

    return "";
  };

  async function get(id: string) {
    try {
      const single: Group =
        listing.length === 0
          ? await DataStore.query(Group as any, id)
          : listing.find((model: any) => model.id === id);

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(data: any) {
    try {
      if (
        (data.upgradeGroup === data.downgradeGroup &&
          data.downgradeGroup !== "-1") ||
        (data.upgradeGroup === data.downgradeGroup &&
          data.upgradeGroup !== "-1")
      ) {
        throw new Error("Upgrade and downgrade group must be different");
      }
      const createInput: CreateGroupInput = {
        accountID: accountsSelected.id,
        name: data.name.toLowerCase(),
        numToUpgrade: data.numToUpgrade ? parseInt(data.numToUpgrade) : 0,
        numToDowngrade: data.numToDowngrade ? parseInt(data.numToDowngrade) : 0,
        upgradeGroup: data.upgradeGroup ? data.upgradeGroup : "-1",
        downgradeGroup: data.downgradeGroup ? data.downgradeGroup : "-1",
        deleted: "0",
        createdAt: new Date().toISOString(),
        createdByID: session.sub,
        createdByName: session.name,
      };

      if (data.description) createInput.description = data.description;
      if (data.color) createInput.color = data.color;
      if (data.requireApproval)
        createInput.requireApproval = data.requireApproval;
      if (data.status) createInput.status = data.status;
      if (data.agents) createInput.agents = data.agents;

      const model: Group = await DataStore.save(new Group(createInput as any));

      const groupSettingData: any = {
        groupID: model.id,
        statusID: "aa42f7e6-1f36-4aa9-bbea-b1d4bcd45966",
        needsVerification: true,
        needsDeposit: true,
        isDefault: true,
        amount: 500,
      };

      try {
        await groupSettingsCreate(groupSettingData);
      } catch (err: Error | any) {
        console.log(err);
      }

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(id: string, data: any) {
    try {
      const original = await get(id);
      if (
        (data.upgradeGroup === data.downgradeGroup &&
          data.downgradeGroup !== "-1") ||
        (data.upgradeGroup === data.downgradeGroup &&
          data.upgradeGroup !== "-1")
      ) {
        throw new Error("Upgrade and downgrade group must be different");
      }

      if (data.numToDowngrade < 0 || data.numToUpgrade < 0) {
        showError("Number to upgrade or downgrade must be greater than 0");
        return;
      }

      await DataStore.save(
        Group.copyOf(original!, (updated) => {
          updated.name =
            data.name !== undefined ? data.name.toLowerCase() : original!.name;
          updated.upgradeGroup =
            data.upgradeGroup !== undefined
              ? data.upgradeGroup
              : original!.upgradeGroup;
          updated.downgradeGroup =
            data.downgradeGroup !== undefined
              ? data.downgradeGroup
              : original!.downgradeGroup;
          updated.numToUpgrade =
            data.numToUpgrade !== undefined
              ? parseInt(data.numToUpgrade)
              : original!.numToUpgrade;
          updated.numToDowngrade =
            data.numToDowngrade !== undefined
              ? parseInt(data.numToDowngrade)
              : original!.numToDowngrade;
          updated.description =
            data.description !== undefined
              ? data.description
              : original!.description;
          updated.color =
            data.color !== undefined ? data.color : original!.color;
          updated.requireApproval =
            data.requireApproval !== undefined
              ? data.requireApproval
              : original!.requireApproval;
          updated.status =
            data.status !== undefined ? data.status : original!.status;
          updated.agents =
            data.agents !== undefined ? data.agents : original!.agents;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(id: string) {
    try {
      const original = await get(id);

      await DataStore.save(
        Group.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(ids: any) {
    for (let id of ids) {
      try {
        await trash(id);
      } catch (err: Error | any) {
        showError(err);
      }
    }

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(id: any) {
    try {
      await DataStore.delete(id);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      console.log(err);
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name"];

  const options: any[] = [];

  if (listing && listing.length > 0) {
    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }
  }

  const api: any = {};

  api[`${listingName}Listing`] = listing;
  api[`${listingName}Options`] = options;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Model`] = Group as any;
  api[`${listingName}Selected`] = selected;
  api[`${listingName}Filters`] = filters;
  api[`${listingName}SelectedFilters`] = selectedFilters;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}ChangeListing`] = (listing: Group[]) => {
    dispatch(setListing(listing));
    dispatch(setFilters(listing.map((model: any) => model.name)));
  };

  api[`${listingName}Search`] = (searchText: string) =>
    dispatch(setSearchText(searchText));
  api[`${listingName}Select`] = (id: string) => dispatch(setSelected(id));
  api[`${listingName}ChangeFilters`] = (filters: string[]) =>
    dispatch(setFilters(filters));
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) =>
    dispatch(setSelectedFilters(filters));

  return api;
};

export default useResource;
