import { API, DataStore, SortDirection, graphqlOperation } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import { useDispatch, useSelector } from "react-redux";
import {
  setListing,
  setListingAll,
  setSearchText,
  setSelected,
} from "../store/ducks/feature";
import { Feature } from "../models";
import { HeadCell } from "../models/dataTable";
import { CreateFeatureInput } from "../models/api";
import useApp from "./useApp";
import { listFeatures } from "../graphql/queries";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { session, showConfirm, showError } = useApp();
  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const listingAll = useSelector(
    (state: any) => state[`${listingName}`]["listingAll"]
  );

  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );
  const selected = useSelector(
    (state: any) => state[`${listingName}`]["selected"]
  );

  async function fetch(startIndex: number, limit: number) {
    try {
      const listing = await DataStore.query(
        Feature as any,
        (model: any) =>
          model
            .deleted("eq", "0")
            .or((model: any) => model.name("contains", searchText)),
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      //dispatch(setListing(listing));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message);
    }
  }

  async function byAccount() {
    try {
      const listing = await DataStore.query(
        Feature as any,
        (model: any) => model.deleted("eq", "0"),
        {
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      dispatch(setListingAll(listing));
      dispatch(setListing(listing));

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message);
    }
  }

  async function get(resourceId: string) {
    try {
      let single: Feature | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === resourceId);
      }

      if (single === undefined) {
        single = await DataStore.query(Feature as any, resourceId);
      }

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(data: any) {
    try {
      const createInput: CreateFeatureInput = {
        precedence: data.precedence,
        name: data.name,
        slug: data.slug,
        deleted: "0",
        createdAt: new Date().toISOString(),
        createdByID: session.sub,
        createdByName: session.name,
      };

      if (data.icon) createInput.icon = data.icon;
      if (data.parent) createInput.parent = data.parent;
      if (data.private) createInput.private = data.private;

      await DataStore.save(new Feature(createInput as any));

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(resourceId: string, data: any) {
    try {
      const original = await get(resourceId);

      await DataStore.save(
        Feature.copyOf(original!, (updated) => {
          updated.precedence = data.precedence
            ? data.precedence
            : original!.precedence;
          updated.name = data.name ? data.name : original!.name;
          updated.slug = data.slug ? data.slug : original!.slug;
          updated.icon = data.icon ? data.icon : original!.icon;
          updated.parent = data.parent ? data.parent : original!.parent;
          updated.private = data.private ? data.private : original!.private;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(resourceId: string) {
    try {
      const original = await get(resourceId);

      await DataStore.save(
        Feature.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }
  async function fetchOnline(session: any) {
    //const listing=await API.graphql(graphqlOperation(listFeatures))
    const listing: any = await API.graphql({
      query: listFeatures,
      variables: {
        limit: 1000,
      },
      authMode: session
        ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        : GRAPHQL_AUTH_MODE.AWS_IAM,
    });

    const features = listing.data.listFeatures.items;
    dispatch(setListingAll(features));
    dispatch(setListing(features));
    return features;
  }

  async function bulkTrash(resourceIds: any) {
    for (let resourceId of resourceIds) {
      try {
        await trash(resourceId);
      } catch (err: Error | any) {
        showError(err);
      }
    }

    dispatch(
      setListing(
        listing.filter((resource: any) => !resourceIds.has(resource.id))
      )
    );

    showConfirm(
      `${resourceIds.size} ${listingName} items has been moved to trash`
    );
  }

  async function remove(resourceId: any) {
    try {
      await DataStore.delete(resourceId);

      dispatch(
        setListing(
          listing.filter((resource: any) => resource.id !== resourceId)
        )
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      console.log(err);
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name"];

  const options: any[] = [{ label: "None", value: "-1" }];

  for (let option of listing) {
    options.push({ label: option.name, value: option.id });
  }

  const api: any = {};

  api[`${listingName}Listing`] = listing;
  api[`${listingName}AllListing`] = listingAll;
  api[`${listingName}Options`] = options;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Model`] = Feature as any;
  api[`${listingName}Selected`] = selected;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchOnline`] = fetchOnline;
  api[`${listingName}FetchByAccount`] = byAccount;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Feature[]) =>
    dispatch(setListing(listing));
  api[`${listingName}Search`] = (searchText: string) =>
    dispatch(setSearchText(searchText));
  api[`${listingName}Select`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useResource;
