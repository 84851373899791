import * as Yup from "yup";
import { Pages } from "../constants/enums";

const useFormValidations = (slug: string, action: string) => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const loginValidation = () =>
    Yup.object().shape({
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .max(40, "Password must not exceed 40 characters"),
    });

  const bookingConfirmationValidation = () =>
    Yup.object().shape({
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
    });

  const nameValidation = () =>
    Yup.object().shape({
      name: Yup.string().required("Name is required"),
    });

  const accountValidation = () =>
    Yup.object().shape({
      domain: Yup.string().required("Domain is required"),
      siteTitle: Yup.string().required("Site Title is required"),
      tagline: Yup.string().required("Tagline is required"),
      description: Yup.string().required("Description is required"),
      siteAddress: Yup.string().required("Site Address is required"),
    });

  const languageValidation = () =>
    Yup.object().shape({
      name: Yup.string().required("Name is required"),
      code: Yup.string().required("Code is required"),
    });

  const featureValidation = () =>
    Yup.object().shape({
      precedence: Yup.string().required("Precedence is required"),
      name: Yup.string().required("Name is required"),
      slug: Yup.string().required("URL is required"),
    });

  const socialLinkValidation = () =>
    Yup.object().shape({
      name: Yup.string().required("Name is required"),
      icon: Yup.string().required("Icon is required"),
      slug: Yup.string().required("URL is required"),
    });

  const statusValidation = () =>
    Yup.object().shape({
      name: Yup.string().required("Name is required"),
      category: Yup.string().required("Category is required"),
    });

  const groupSettingValidation = () =>
    Yup.object().shape({
      groupID: Yup.string().required("Group is required"),
      statusID: Yup.string().required("Status is required"),
    });

  const usersValidation = () =>
    Yup.object().shape({
      phone_number: Yup.string()
        .max(11, "Phone number must be exactly 11 digits only")
        .min(11, "Phone number must be exactly 11 digits only")
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Phone number is required"),
      given_name: Yup.string().required("First Name is required"),
      family_name: Yup.string().required("Last Name is required"),
    });

  const tableValidation = () =>
    Yup.object().shape({
      name: Yup.string().required("Name is required"),
      label: Yup.string().required("Label is required"),
      width: Yup.string().required("Width is required"),
      height: Yup.string().required("Height is required"),
    });

  const adminRolesValidation = () =>
    Yup.object().shape({
      name: Yup.string().required("Name is required"),
    });
  const adminGroupsValidation = () =>
    Yup.object().shape({
      name: Yup.string().required("Name is required"),
    });

  const planItemValidation = () =>
    Yup.object().shape({
      areaID: Yup.string().required("Area is required"),
      xPosition: Yup.string().required("X Coordinates is required"),
      yPosition: Yup.string().required("Y Coordinates is required"),
      name: Yup.string().required("Name is required"),
    });

  const tierValidation = () =>
    Yup.object().shape({
      name: Yup.string().required("Name is required"),
      starting_value: Yup.string().required("starting value is required"),
      is_points_expire: Yup.string().required("is points expire is required"),
      welcome_points: Yup.string().required("welcome points is required"),
      points_gain_factor: Yup.string().required(
        "points gain factor is required"
      ),
      money_redeem_factor: Yup.string().required(
        "money redeem factor is required"
      ),
    });

  const getValidationSchema = () => {
    switch (slug) {
      case Pages.LOGIN:
        return loginValidation();
      case Pages.ACCOUNTS:
        return accountValidation();
      case Pages.AREAS:
        return nameValidation();
      case Pages.CONCEPTS:
        return nameValidation();
      case Pages.FEATURES:
        return featureValidation();
      case Pages.LANGUAGES:
        return languageValidation();
      case Pages.SOCIAL_LINKS:
        return socialLinkValidation();
      case Pages.GROUPS:
        return nameValidation();
      case Pages.RESERVATION_STATUS:
        return statusValidation();
      case Pages.FLAGS:
        return nameValidation();
      case Pages.INTERESTS:
        return nameValidation();
      case Pages.GROUP_SETTINGS:
        return groupSettingValidation();
      case Pages.GUESTS:
        return usersValidation();
      case Pages.TABLES:
        return tableValidation();
      case Pages.PLAN_ITEMS:
        return planItemValidation();
      case Pages.TIME_SLOTS:
        return nameValidation();
      case Pages.BOOKING_CONFIRMATION:
        return bookingConfirmationValidation();
      case Pages.ADMIN_ROLES:
        return adminRolesValidation();
      case Pages.ADMIN_GROUPS:
        return adminGroupsValidation();
      case Pages.TIERS:
        return tierValidation();
      default:
        return Yup.object();
    }
  };

  return {
    getValidationSchema,
  };
};

export default useFormValidations;
